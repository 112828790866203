import { z } from 'zod'

// models
import { iconResponse } from '@/models/file'

export const profileData = z.object({
  name: z.string(),
  id: z.number(),
  icon: iconResponse,
  userId: z.number(),
  introduction: z.string(),
  platformCode: z.string(),
  topPageAvatarId: z.number().nullable(),
  showAchievements: z.boolean(),
  isCompleteTutorial: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
  vketId: z.string(),
  followCount: z.number(),
  followerCount: z.number(),
  achievements: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      label: z.string(),
      description: z.string(),
      titleEn: z.string(),
      descriptionEn: z.string(),
      thumbnail: z.object({ url: z.string() }),
      ingameThumbnail: z.object({ url: z.string().nullable() }),
      accomplishedAt: z.string().optional().nullable(), // 獲得日時。ログイン時 + 獲得済みの時返ってくる
      createdAt: z.string(),
      updatedAt: z.string(),
    })
  ),
  achievementCount: z.number(),
  user: z.object({
    id: z.number(),
    vketId: z.string(),
  }),
  blockStatus: z.boolean().optional(),
  blockedStatus: z.boolean().optional(),
  followStatus: z.boolean().optional(),
  followerStatus: z.boolean().optional(),
})
export type ProfileData = z.infer<typeof profileData>

export const profileDetails = z.object({
  id: z.number(),
  userId: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string(),
  platformCode: z.string(),
  icon: iconResponse,
  topPageAvatarId: z.number(),
  blockStatus: z.boolean().optional(),
  blockedStatus: z.boolean().optional(),
  followStatus: z.boolean().optional(),
  followerStatus: z.boolean().optional(),
  followCount: z.number(),
  followerCount: z.number(),
  mutualFollowCount: z.number().optional(),
  mutualFollowerCount: z.number().optional(),
  achievements: z.array(
    z.object({
      id: z.number(),
      title: z.string(),
      label: z.string(),
      description: z.string(),
      titleEn: z.string(),
      descriptionEn: z.string(),
      thumbnail: z.object({ url: z.string() }),
      ingameThumbnail: z.object({ url: z.string().nullable() }),
      accomplishedAt: z.string().optional().nullable(), // 獲得日時。ログイン時 + 獲得済みの時返ってくる
      createdAt: z.string(),
      updatedAt: z.string(),
    })
  ),
  achievementCount: z.number(),
  showAchievements: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
  user: z.object({
    id: z.number(),
    vketId: z.string(),
  }),
  sendMessage: z.boolean(),
})
export type ProfileDetails = z.infer<typeof profileDetails>

// NOTE: 全項目ではないユーザープロフィール用
export const user = z.object({
  id: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string().nullable(),
  icon: iconResponse,
  topPageAvatarId: z.number().nullable().optional(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
})

export type User = z.infer<typeof user>

/* get */
export const getProfileRequest = z.object({
  vketId: z.string(),
})

export type GetProfileRequest = z.infer<typeof getProfileRequest>

// @see https://hikky.atlassian.net/wiki/spaces/VRMMO/pages/534249506#Usage(Response)1
export const getProfileResponse = z.object({
  profile: profileData,
})

export type GetProfileResponse = z.infer<typeof getProfileResponse>

export const getProfileDetailsResponse = z.object({
  profile: profileDetails,
})

export type GetProfileDetailsResponse = z.infer<
  typeof getProfileDetailsResponse
>

/* post */
export const postProfileRequest = z.object({
  name: z.string(),
  introduction: z.string(),
  topPageAvatarId: z.number().optional(),
  icon: z.instanceof(File).nullable(),
})

export type PostProfileRequest = z.infer<typeof postProfileRequest>

export const postProfileResponse = z.object({
  profile: profileData,
})

export type PostProfileResponse = z.infer<typeof postProfileResponse>

// プロフィール一括取得リクエスト
export const getProfileListRequest = z.object({
  vketIds: z.array(z.string()),
})
export type GetProfileListRequest = z.infer<typeof getProfileListRequest>
// プロフィール一括取得レスポンス
export const getProfileListResponse = z.object({
  profiles: z.array(profileData),
})
export type GetProfileListResponse = z.infer<typeof getProfileListResponse>

/* patch */
export const patchProfileRequest = z.object({
  name: z.string(),
  introduction: z.string().nullable(),
  topPageAvatarId: z.number().optional().nullable(),
  deleteTopPageAvatarId: z.boolean().optional(),
  icon: z.instanceof(File).optional().nullable(),
  showAchievements: z.boolean().optional(),
})

export type PatchProfileRequest = z.infer<typeof patchProfileRequest>

export const patchProfileResponse = z.object({
  profile: profileData,
})

export type PatchProfileResponse = z.infer<typeof patchProfileResponse>
