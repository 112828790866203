import { z } from 'zod'

export const VISIT_TYPE = {
  FOOTPRINT: 'footprint',
  FOOTPRINTED: 'footprinted',
} as const

export const visitType = z.enum([VISIT_TYPE.FOOTPRINT, VISIT_TYPE.FOOTPRINTED])

export type VisitType = z.infer<typeof visitType>

export const worldVisit = z.object({
  id: z.number(),
  worldSetId: z.number(),
  vketId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  user: z.object({
    id: z.number(),
    vketId: z.string(),
    createdAt: z.string(),
    updatedAt: z.string(),
    name: z.string(),
    introduction: z.string(),
    icon: z.object({
      url: z.string(),
    }),
    topPageAvatarId: z.number(),
    followStatus: z.boolean(),
  }),
})

export type WorldVisit = z.infer<typeof worldVisit>

// ワールドへの入室に必要な情報
export const entryWorldType = z.object({
  worldId: z.string(),
  worldSetId: z.number().optional(),
})
export type EntryWorldType = z.infer<typeof entryWorldType>
