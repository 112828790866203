import { z } from 'zod'
import { thumbnail } from './file'

export const LIST_TYPE = {
  NEW: 'new',
  POPULAR: 'popular',
  OFFICIAL: 'official',
} as const

export const listType = z.enum([
  LIST_TYPE.NEW,
  LIST_TYPE.POPULAR,
  LIST_TYPE.OFFICIAL,
])

export type ListType = z.infer<typeof listType>

export const FILTER_TYPE = {
  PICKUP: 'pickup',
  OFFICIAL: 'official',
  USER: 'user',
} as const

export const filterType = z.enum([
  FILTER_TYPE.PICKUP,
  FILTER_TYPE.OFFICIAL,
  FILTER_TYPE.USER,
])

export type FilterType = z.infer<typeof filterType>

// ソート関連
export const SORT_TYPE = {
  NEWER: 'newer',
  OLDER: 'older',
  POPULAR: 'popular',
} as const

export const sortType = z.enum([
  SORT_TYPE.NEWER,
  SORT_TYPE.OLDER,
  SORT_TYPE.POPULAR,
])

export type SortType = z.infer<typeof sortType>

export const worldSortData = z.object({
  value: sortType,
  text: z.string(),
})

export type WorldSortData = z.infer<typeof worldSortData>

export const worldImage = z.object({
  url: z.string(),
})

export const worldPortal = z.object({
  worldId: z.string(),
  worldSetId: z.number(),
  id: z.number(),
  platformCode: z.string(),
  name: z.string(),
  description: z.string(),
  thumbnail,
  publishedAt: z.union([z.instanceof(Date), z.string()]), // date
  creatorVketId: z.string(),
  creatorName: z.string(),
  creatorNameEn: z.string(),
  numberOfLikes: z.number(),
  visitCount: z.number(),
  outGameUrl: z.string().nullable(),
  outGameName: z.string().nullable(),
  ingameUrl: z.string(),
  isPickup: z.boolean(),
  isOfficial: z.boolean(),
  eventUse: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
  liked: z.boolean().optional(),
  images: z.array(worldImage),
})
export type WorldPortal = z.infer<typeof worldPortal>

// 検索クエリ
export const searchQueryOptions = z.object({
  keyword: z.string().optional(),
  sort: sortType.optional(),
  isOfficial: z.boolean().optional(),
  isUser: z.boolean().optional(),
  isPickUp: z.boolean().optional(),
})

export type SearchQueryOptions = z.infer<typeof searchQueryOptions>

// いいね作成
export const postWorldLikesRequest = z.object({
  worldSetId: z.string(),
})
export type PostWorldLikesRequest = z.infer<typeof postWorldLikesRequest>

// いいね
export const worldLikesRequest = z.object({
  worldSetId: z.string(),
})
export type WorldLikesRequest = z.infer<typeof worldLikesRequest>

// いいねレスポンス
export const getWorldLikesResponse = z.object({
  world: worldPortal,
})

export type GetWorldLikesResponse = z.infer<typeof getWorldLikesResponse>
