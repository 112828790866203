import { z } from 'zod'
import { useApi } from './useApi'
import { presetHousingHome } from '@/models/presetHousingHome'
import { presetHousingScene } from '@/models/presetHousingScene'

const prefixHome: string = '/preset_housing_homes'
const prefixScenes: string = '/preset_housing_scenes'

export const getPresetHousingHomesRequest = z.object({
  tutorialUse: z.boolean(),
})

export type GetPresetHousingHomesRequest = z.infer<
  typeof getPresetHousingHomesRequest
>

export const getPresetHousingHomeRequest = z.object({
  id: z.string(),
})

export type GetPresetHousingHomeRequest = z.infer<
  typeof getPresetHousingHomeRequest
>

export const getPresetHousingHomesResponse = z.object({
  presetHousingHomes: z.array(presetHousingHome),
})

export type GetPresetHousingHomesResponse = z.infer<
  typeof getPresetHousingHomesResponse
>

export const getPresetHousingHomeResponse = z.object({
  presetHousingHome,
})

export type GetPresetHousingHomeResponse = z.infer<
  typeof getPresetHousingHomeResponse
>

export const getPresetHousingScenesResponse = z.object({
  presetHousingScenes: z.array(presetHousingScene),
})

export type GetPresetHousingScenesResponse = z.infer<
  typeof getPresetHousingScenesResponse
>

export const useHomeRepository = () => {
  const api = useApi()
  return {
    get: {
      getPresetHousingHomes({
        tutorialUse,
      }: GetPresetHousingHomesRequest): Promise<GetPresetHousingHomesResponse> {
        return api.get(`${prefixHome}?tutorial_use=${tutorialUse.toString()}`)
      },
      getPresetHousingHome({
        id,
      }: GetPresetHousingHomeRequest): Promise<GetPresetHousingHomeResponse> {
        return api.get(`${prefixHome}/${id}`)
      },
      getPresetHousingScenes(): Promise<GetPresetHousingScenesResponse> {
        return api.get(`${prefixScenes}`)
      },
    },
    post: {},
    put: {},
    patch: {},
    delete: {},
  }
}
