// note: APIの向き先に応じてファイルを新規作成する
import type { InjectionKey } from 'vue'
import type { FetchOptions } from 'ohmyfetch'
import camelcaseKeys from 'camelcase-keys'
import { useOhmyfetch } from './useOhmyfetch'
import { useAuth } from '@/composables/useAuth'

export const useChannelSessionApi = () => {
  const config = useRuntimeConfig()
  const baseURL = config.public.NUXT_ENV_CHANNEL_SESSION_API_BASE_URL
  // NUXT_ENV_API_URL = process.env.USE_API_MOCK ? '/api/mock' : '/api'

  const { state, isGottenMe, getTokens, logout } = useAuth()
  const fetch = useOhmyfetch({
    baseURL,
    prefixPath: '',
  })
  const api = fetch.api
  const headers: FetchOptions['headers'] = {
    Accept: 'application/json',
  }

  const defaultFetchOptions = (): FetchOptions => {
    return {
      headers,
      onResponse: async (ctx) => {
        // レスポンスヘッダーから認証用トークンを取得する
        if (
          ctx &&
          ctx.response &&
          ctx.response.headers &&
          ctx.response.headers.get('X-User-Jwt-Session')
        ) {
          ctx.response._data['user_jwt'] =
            ctx.response.headers.get('X-User-Jwt-Session')
        }

        if (!ctx.response._data || typeof ctx.response._data !== 'object') {
          return
        }
        ctx.response._data = await camelcaseKeys(ctx.response._data, {
          deep: true,
        })
      },
      onResponseError: async (ctx) => {
        if (
          process.client &&
          ctx &&
          ctx.response &&
          ctx.response.status === 401
        ) {
          await getTokens()
        }
      },
    }
  }

  // cookieから認証用トークンを取得しなおす
  const refreshIdToken = async () => {
    if (!isGottenMe.value) return
    try {
      await getTokens()
      if (state.value.idToken) {
        headers.Authorization = `Bearer ${state.value.idToken}`
      }
    } catch (e) {
      console.error(e)
      logout()
    }
  }

  return {
    get: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.get(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
        headers,
      })
    },
    post: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.post(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
    put: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.put(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
    patch: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.patch(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
    delete: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.delete(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
  }
}

export type OhmyfetchComposable = ReturnType<typeof useOhmyfetch>

export const apiInjectionKey: InjectionKey<OhmyfetchComposable> =
  Symbol('ohmyfetch')
