import { z } from 'zod'
import { useApi } from './useApi'
import { worldVisit } from '@/models/visitWorld'

// types
export const getWorldVisitsFootprintRequest = z.object({
  limit: z.number(),
  offset: z.number(),
})
export type GetWorldVisitsFootprintRequest = z.infer<
  typeof getWorldVisitsFootprintRequest
>
export const getWorldVisitsFootprintResponse = z.object({
  worldVisits: z.array(worldVisit),
  visitCount: z.number(),
})
export type GetWorldVisitsFootprintResponse = z.infer<
  typeof getWorldVisitsFootprintResponse
>

export const getWorldVisitsFootprintedRequest = z.object({
  limit: z.number(),
  offset: z.number(),
})
export type GetWorldVisitsFootprintedRequest = z.infer<
  typeof getWorldVisitsFootprintedRequest
>
export const getWorldVisitsFootprintedResponse = z.object({
  worldVisits: z.array(worldVisit),
  visitCount: z.number(),
})
export type GetWorldVisitsFootprintedResponse = z.infer<
  typeof getWorldVisitsFootprintedResponse
>

const prefix = '/world_visits'
const prefixFootprint = '/user/world_visits/footprint'
const prefixFootprinted = '/user/world_visits/footprinted'

export const useVisitWorldRepository = () => {
  const api = useApi()

  return {
    get: {
      /**
       * 自分が訪問したルームのユーザーの一覧を取得
       */
      getFootprint(
        vketId: string,
        params: GetWorldVisitsFootprintRequest
      ): Promise<GetWorldVisitsFootprintResponse> {
        return api.get(`${prefixFootprint}/${vketId}`, { params })
      },

      /**
       * 自分のルームに訪問したユーザーの一覧を取得
       */
      getFootprinted(
        params: GetWorldVisitsFootprintedRequest
      ): Promise<GetWorldVisitsFootprintedResponse> {
        return api.get(prefixFootprinted, { params })
      },
    },
    post: {},
    put: {},
    patch: {},
    delete: {},
  }
}
