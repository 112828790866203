import {
  GetAvatarsResponse,
  GetAvatarResponse,
  GetAvatarUpdateURLResponse,
  PostAvatarResponse,
  AvatarParams,
  PutAvatarResponse,
} from '@/models/avatar'
import { useAssetApi } from '@/composables/repository/useAssetApi'
import { officialAvatars } from '@/mocks/avatar'
import {
  PostAssetRequest,
  PostAssetResponse,
} from '@/composables/repository/useAssetRepository'

const prefixPath = '/assets'

export const useAvatarRepository = () => {
  const api = useAssetApi(prefixPath)

  return {
    get: {
      getAvatars(limit: number): Promise<GetAvatarsResponse> {
        return api.get(`?asset_type=avatar&limit=${limit}`)
      },
      getAvatarsWithPager(
        limit: number,
        offset: number
      ): Promise<GetAvatarsResponse> {
        const query = new URLSearchParams({
          limit: limit.toString(),
          offset: offset.toString(),
        })
        return api.get(`?asset_type=avatar&${query.toString()}`)
      },
      getAvatar(id: number): Promise<GetAvatarResponse> {
        return api.get(`/${id}`)
      },
      getAvatarUpdateURL(
        id: number,
        params: AvatarParams
      ): Promise<GetAvatarUpdateURLResponse> {
        return api.get(`/${id}/update_url`, { params })
      },
      getAvatarPublished(id: number): Promise<GetAvatarResponse> {
        return api.get(`/published/${id}`)
      },
      getAvatarsPublished(): Promise<GetAvatarsResponse> {
        return api.get(`/published?asset_type=avatar`)
      },
      getAvatarsPublishedWithPager(
        limit: number,
        offset: number,
        vketId?: string
      ): Promise<GetAvatarsResponse> {
        // NOTE: URLSearchParamsへundefined渡せない
        const query = vketId
          ? new URLSearchParams({
              vket_id: vketId,
              limit: limit.toString(),
              offset: offset?.toString(),
            })
          : new URLSearchParams({
              limit: limit.toString(),
              offset: offset.toString(),
            })
        return api.get(`/published?asset_type=avatar&${query.toString()}`)
      },
      getAvatarsPreset(): Promise<GetAvatarsResponse> {
        return api.get(`/preset?asset_type=avatar`)
      },
    },
    post: {
      postAvatar(data: PostAssetRequest): Promise<PostAssetResponse> {
        return api.post(``, {
          body: data,
        })
      },
    },
    put: {
      putAvatar(id: number, params: AvatarParams): Promise<PutAvatarResponse> {
        return api.put(`/${id}`, {
          body: params,
        })
      },
      putAvatarDetail(
        id: number,
        versionId: number,
        params: AvatarParams
      ): Promise<void> {
        return api.put(`/${id}/versions/${versionId}`, {
          body: params,
        })
      },
    },
    delete: {
      deleteAvatar(id: number): Promise<void> {
        return api.delete(`/${id}`)
      },
    },
  }
}
