import { useApi } from './useApi'
// models
import { GetWorldLikesResponse } from '@/models/worldPortal'

const prefix: string = '/world_likes'

export const useWorldLikeRepository = () => {
  const api = useApi()
  return {
    get: {},
    post: {
      postWorldLikes: (worldSetId: number): Promise<GetWorldLikesResponse> => {
        return api.post(`${prefix}`, {
          body: {
            worldSetId,
          },
        })
      },
    },
    put: {},
    patch: {},
    delete: {
      deleteWorldLikes: (
        worldSetId: number
      ): Promise<GetWorldLikesResponse> => {
        return api.delete(`${prefix}/${worldSetId}`)
      },
    },
  }
}
