import { z } from 'zod'
import {
  accessibleStatusType,
  categoryType,
  item,
  targetAge,
} from '@/models/cloudstore'

export const salesSummary = z.object({
  sales: z.number(),
  fee: z.number(),
  revenue: z.number(),
})
export type SalesSummary = z.infer<typeof salesSummary>

export const salesByPeriod = z.object({
  date: z.string(), // YYYYMM or YYYYMMDD(年別の場合月、月別の場合日)
  sales: z.number(),
  fee: z.number(),
  revenue: z.number(),
  count: z.number(),
})
export type SalesByPeriod = z.infer<typeof salesByPeriod>

export const salesByItem = z.object({
  date: z.string(), // YYYY or YYYYMM(年別の場合年、月別の場合月)
  itemId: z.number(),
  sales: z.number(),
  fee: z.number(),
  revenue: z.number(),
  count: z.number(),
  item,
})
export type SalesByItem = z.infer<typeof salesByItem>

export const orderItem = z.object({
  id: z.number(),
  orderId: z.number(),
  itemId: z.number(),
  name: z.string(),
  nameEn: z.string(),
  assetId: z.number(),
  category: categoryType,
  thumbnailUrl: z.string(),
  youtubeUrl: z.string().nullable(),
  description: z.string(),
  descriptionEn: z.string().nullable(),
  termsOfUse: z.string().nullable(),
  termsOfUseEn: z.string().nullable(),
  targetAge,
  accessibleStatus: accessibleStatusType,
  price: z.number().nullable(),
  fee: z.number().nullable(),
  discountRate: z.number().nullable(),
  numberOfLikes: z.number(),
  secondaryUse: z.boolean(),
  commercialUse: z.boolean(),
  isOfficial: z.boolean(),
  isPickup: z.boolean(),
  closingDate: z.union([z.instanceof(Date), z.string()]), // 決済日時
  /**
   * countは販売数を表す。2024/03/26現在、同じアイテムを複数同時購入の仕様がないためにバックエンドには未実装。
   * フロント側で1を固定で設定する。
   * @see https://discord.com/channels/596169759045976094/1040185303496933406/1222012569951141979
   */
  count: z.number(),
})
export type OrderItem = z.infer<typeof orderItem>

export const salesListOption = z.union([
  z.literal('byPeriod'),
  z.literal('byItem'),
])
export type SalesListOption = z.infer<typeof salesListOption>

export const transferType = z.union([
  z.literal('bank'),
  z.literal('paypal'),
  z.literal('not-set'),
])
export type TransferType = z.infer<typeof transferType>
