import snakecaseKeys from 'snakecase-keys'
/**
 *
 * @param obj 変換元オブジェクト
 * @param form 再帰処理に利用
 * @param namespace 再帰処理に利用
 * @returns
 */
export const objectToFormData = (obj: Object) => {
  const formData = new FormData()
  return convert(obj, formData)
}

const convert = <T>(
  // NOTE: snakecaseKeys側の型定義がanyのため合わせている
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: T extends Record<string, any> ? T : never,
  formData: FormData,
  namespace?: string
) => {
  if (obj === undefined) return

  // Note: deep: trueにするとファイルオブジェクトが壊れるのでfalseにして再帰的に第一階層だけスネークケースにする
  const snakeCaseParams = snakecaseKeys(obj, {
    deep: false,
  })
  for (const property in snakeCaseParams) {
    if (Object.prototype.hasOwnProperty.call(snakeCaseParams, property)) {
      const formKey = namespace ? `${namespace}[${property}]` : property
      if (
        snakeCaseParams[property] === null ||
        ['Boolean', 'Number', 'String', 'File'].includes(
          snakeCaseParams[property]?.constructor.name || ''
        )
      ) {
        formData.append(formKey, snakeCaseParams[property])
        continue
      }
      if (snakeCaseParams[property]?.constructor.name === 'Array') {
        // NOTE: snakecaseKeys側の型定義がanyのため合わせている
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        snakeCaseParams[property].forEach((value: any) => {
          if (
            value === null ||
            ['Boolean', 'Number', 'String', 'File'].includes(
              value.constructor.name
            )
          ) {
            formData.append(`${formKey}[]`, value)
            return
          }

          // NOTE:もっと階層が深いケースだがデータを作れなかったので動作確認が取れていない
          convert(value, formData, `${formKey}[]`)
        })
        continue
      }
      convert(snakeCaseParams[property], formData, formKey)
    }
  }
  return formData
}
