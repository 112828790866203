import { z } from 'zod'

export const CATEGORY_TYPE = {
  UPDATE: 'update',
  EVENT: 'event',
  DEFECT: 'defect',
} as const

export const categoryType = z.enum([
  CATEGORY_TYPE.UPDATE,
  CATEGORY_TYPE.EVENT,
  CATEGORY_TYPE.DEFECT,
])

export type CategoryType = z.infer<typeof categoryType>

export const newsItem = z.object({
  id: z.number(),
  category: categoryType,
  title: z.string(),
  titleEn: z.string(),
  banner: z.object({
    url: z.string(),
  }),
  bannerEn: z.object({
    url: z.string(),
  }),
  text: z.string().nullable(),
  textEn: z.string().nullable(),
  detailUrl: z.string(),
  detailUrlEn: z.string(),
  platformCode: z.string(),
  displayAt: z.string(),
  finishAt: z.string(),
  isPublished: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
})

export type NewsItem = z.infer<typeof newsItem>
