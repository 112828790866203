import { useApi } from './useApi'
import {
  GetRoomRequest,
  GetMyRoomRequest,
  PatchRoomResponse,
  GetMyRoomResponse,
  GetRoomsRequest,
  GetRoomsResponse,
  PatchRoomRequest,
} from '@/models/housing'

const prefix: string = '/housing_scenes'

export const useHousingRepository = () => {
  const api = useApi()
  return {
    get: {
      getMyRoom({ worldId }: GetMyRoomRequest): Promise<GetMyRoomResponse> {
        return api.get(`${prefix}/${worldId}`)
      },
      getMyRooms(): Promise<GetRoomsResponse> {
        return api.get(`${prefix}`)
      },
      getRooms({ userId }: GetRoomsRequest): Promise<GetRoomsResponse> {
        return api.get(`${prefix}/published?user_id=${userId}`)
      },
      getRoom({ worldId }: GetRoomRequest): Promise<GetMyRoomResponse> {
        return api.get(`${prefix}/published/${worldId}`)
      },
    },
    post: {},
    put: {},
    patch: {
      patchMyRoom({
        vketId,
        room,
      }: PatchRoomRequest): Promise<PatchRoomResponse> {
        return api.patch(`${prefix}/MyRoom-${vketId}`, {
          body: room,
        })
      },
    },
    delete: {},
  }
}
