import { z } from 'zod'

export const myVketTabIcon = z.enum(['event', 'world'])

export const myVketTab = z.object({
  path: z.string(),
  labelJa: z.string(),
  labelEn: z.string(),
  wide: z.boolean().optional(),
  icon: myVketTabIcon.optional(),
})

export type MyVketTab = z.infer<typeof myVketTab>

// snsタブリスト
export const snsTabList: MyVketTab[] = [
  {
    path: '/mypage/follow',
    labelJa: 'フォロー',
    labelEn: 'Follow',
  },
  {
    path: '/mypage/follower',
    labelJa: 'フォロワー',
    labelEn: 'Follower',
  },
  {
    path: '/mypage/message',
    labelJa: 'メッセージ',
    labelEn: 'Message',
  },
]

// いいねタブリスト
export const likeTabList: MyVketTab[] = [
  {
    path: '/mypage/like/store',
    labelJa: 'ストア',
    labelEn: 'Store',
  },
  {
    path: '/mypage/like/event',
    labelJa: 'イベント',
    labelEn: 'Event',
  },
  {
    path: '/mypage/like/world',
    labelJa: 'ワールド',
    labelEn: 'World',
  },
]

// イベント管理タブリスト
export const eventManagementTabList: MyVketTab[] = [
  {
    path: '/mypage/event/participating',
    labelJa: '参加イベント',
    labelEn: 'Participating Events',
  },
  {
    path: '/mypage/event/organised',
    labelJa: '主催イベント',
    labelEn: 'Hosting Events',
  },
]

export const myVketSelectItem = z.object({
  value: z.string(),
  labelJa: z.string(),
  labelEn: z.string(),
})

export type MyVketSelectItem = z.infer<typeof myVketSelectItem>

export const stepperType = z.object({
  id: z.number(),
  title: z.string(),
  step: z.string().optional(),
})

export type StepperType = z.infer<typeof stepperType>
