import { z } from 'zod'

import { useApi } from './useApi'

// models
import { filterType, sortType, worldPortal } from '@/models/worldPortal'
// modules
import { createUrlSearchParams } from '@/utils/url'

const prefix: string = '/world_portals'
const prefixRecommended: string = '/recommended_worlds'

// ワールド一覧リクエスト
export const getWorldPortalsRequest = z.object({
  creatorVketId: z.string().optional(),
  eventUse: z.boolean().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  keyword: z.string().optional(),
  filter: filterType.optional(),
  sort: sortType.optional(),
})

export type GetWorldPortalsRequest = z.infer<typeof getWorldPortalsRequest>

export const getWorldPortalsResponse = z.object({
  worldPortals: z.array(worldPortal),
  worldPortalCount: z.number(),
})

export type GetWorldPortalsResponse = z.infer<typeof getWorldPortalsResponse>

export const getRecommendWorldPortalListResponse = z.object({
  worldPortals: z.array(worldPortal),
})

export type GetRecommendWorldPortalListResponse = z.infer<
  typeof getRecommendWorldPortalListResponse
>

export const getWorldPortalDetailResponse = z.object({
  worldPortal,
})

export type GetWorldPortalDetailResponse = z.infer<
  typeof getWorldPortalDetailResponse
>

export const useWorldPortalRepository = () => {
  const api = useApi()
  return {
    get: {
      getWorldPortals(
        param: GetWorldPortalsRequest
      ): Promise<GetWorldPortalsResponse> {
        const queryContent = {
          limit: param.limit,
          offset: param.offset,
          filter: param.filter,
          keyword: param.keyword,
          sort: param.sort,
          event_use: param.eventUse,
          creator_vket_id: param.creatorVketId,
        }

        const query = createUrlSearchParams(queryContent)
        return api.get(`${prefix}/${query ? '?' + query : ''}`)
      },
      getRecommendWorldPortalList(): Promise<GetRecommendWorldPortalListResponse> {
        return api.get(prefixRecommended)
      },
      getWorldPortalDetail(
        worldPortalId: number
      ): Promise<GetWorldPortalDetailResponse> {
        return api.get(`${prefix}/${worldPortalId}`)
      },
    },
    post: {},
    put: {},
    patch: {},
    delete: {},
  }
}
