import { z } from 'zod'

// repository
import { useAssetApi } from '@/composables/repository/useAssetApi'

// models
import {
  AssetData,
  settingLabel,
  AssetType,
  assetData,
  subCategory,
  objectType,
  assetDetailData,
} from '@/models/asset'
import { TARGET_AGE } from '@/utils/constants'

// アセット取得
export const getAssetResponse = z.object({
  asset: assetDetailData,
})
export type GetAssetResponse = z.infer<typeof getAssetResponse>

// アセット登録
export const postAssetRequest = z.object({
  name: z.string(),
  settingLabel: settingLabel.optional(),
  description: z.string().optional(),
  thumbnail: z
    .union([z.instanceof(File), z.string()])
    .nullable()
    .optional(),
  remoteThumbnailUrl: z.string().nullable(),
  published: z.boolean().optional(),
  sdkVersion: z.string().optional(),
  worldId: z.string().optional(),
  subType: subCategory.optional(),
  objectType: objectType.optional(),
  jsonPath: z.string().optional(),
  polygonCount: z.number().optional(),
  ingameThumbnail: z
    .union([z.instanceof(File), z.string()])
    .nullable()
    .optional(),
  remoteIngameThumbnailUrl: z.string().optional(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]),
  motionType: z.string(),
})
export const postAssetResponse = z.object({
  presignedUrl: z.string(),
  expiresOn: z.union([z.instanceof(Date), z.string()]), // date,
  asset: assetData,
  version: z.string(),
})
export type PostAssetRequest = z.infer<typeof postAssetRequest>
export type PostAssetResponse = z.infer<typeof postAssetResponse>

// アセットデータ更新
export const putAssetRequest = z.object({
  name: z.string(),
  description: z.string().optional(),
  published: z.boolean().optional(),
})
export const putAssetResponse = z.object({
  asset: assetDetailData,
})
export type PutAssetRequest = z.infer<typeof putAssetRequest>
export type PutAssetResponse = z.infer<typeof putAssetResponse>

// アセットデータ更新
export const putAssetVersionsRequest = z.object({
  thumbnail: z
    .union([z.instanceof(File), z.string()])
    .nullable()
    .optional(),
  remoteThumbnailUrl: z.string().optional(),
  ingameThumbnail: z
    .union([z.instanceof(File), z.string()])
    .nullable()
    .optional(),
  remoteIngameThumbnailUrl: z.string().optional(),
  motionType: z.string(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]),
  jsonPath: z.string().optional(),
  polygonCount: z.number().optional(),
})
export const putAssetVersionsResponse = z.object({
  asset: assetDetailData.omit({
    published: true,
  }),
})
export type PutAssetVersionsRequest = z.infer<typeof putAssetVersionsRequest>
export type PutAssetVersionsResponse = z.infer<typeof putAssetVersionsResponse>

// アセット更新用URL作成
export const getAssetUpdateUrlResponse = z.object({
  presignedUrl: z.string(),
  expiresOn: z.union([z.instanceof(Date), z.string()]), // date,
  version: z.string(),
})
export type GetAssetUpdateUrlResponse = z.infer<
  typeof getAssetUpdateUrlResponse
>

export const assetUploaderForm = postAssetRequest
  .extend({
    pictures: z.array(z.union([z.instanceof(File), z.string()])),
    price: z.number(),
    commodityUse: z.boolean(),
    secondaryUse: z.boolean(),
    limited: z.boolean(),
    canPurchase: z.boolean(),
  })
  // NOTE:現状motionTypeは固定値のためフォームでの設定はないのでomitしている
  .omit({ motionType: true })
export type AssetUploaderForm = z.infer<typeof assetUploaderForm>

const prefixPath = '/assets'

export type GetAssetWithPagerResponse = {
  assets: AssetData[]
}

export const useAssetRepository = () => {
  const api = useAssetApi(prefixPath)

  return {
    get: {
      getAsset(id: number): Promise<GetAssetResponse> {
        return api.get(`/${id}`)
      },
      getAssetWithPager(
        assetType: AssetType,
        limit: number,
        offset: number
      ): Promise<GetAssetWithPagerResponse> {
        const query = new URLSearchParams({
          limit: limit.toString(),
          offset: offset.toString(),
        })
        return api.get(`?asset_type=${assetType}&${query.toString()}`)
      },
      getAssetUpdateUrl(id: number): Promise<GetAssetUpdateUrlResponse> {
        return api.get(`/${id}/update_url`)
      },
    },
    post: {
      postAsset(params: PostAssetRequest): Promise<PostAssetResponse> {
        return api.post('', {
          body: params,
        })
      },
    },
    put: {
      putAsset(params: PutAssetRequest, id: number): Promise<PutAssetResponse> {
        return api.put(`/${id}`, {
          body: params,
        })
      },
      putAssetVersions(
        params: PutAssetVersionsRequest,
        id: number,
        versionId: number
      ): Promise<PutAssetVersionsResponse> {
        return api.put(`/${id}/versions/${versionId}`, { body: params })
      },
    },
    delete: {
      deleteAsset(id: number): Promise<void> {
        return api.delete(`/${id}`)
      },
    },
  }
}
