import { z } from 'zod'
import { PaymentMethod } from '@stripe/stripe-js'
import { useCloudstoreApi } from '@/composables/repository/useCloudstoreApi'
import { order, wallet } from '@/models/cloudstore'

const versionCode = 'v1'
const prefix = 'payment/stripe'

// stripeのシークレットIDを取得するAPI
export const createWalletSecretResponse = z.object({ secret: z.string() })
export type CreateWalletSecretResponse = z.infer<
  typeof createWalletSecretResponse
>

export type PaymentMethodId = string | PaymentMethod | null | undefined
export type PostPaymentStripeRequest = {
  uuid: string
  paymentMethodId: PaymentMethodId
}

export const postPaymentStripeResponse = z.object({
  order,
})
export type PostPaymentStripeResponse = z.infer<
  typeof postPaymentStripeResponse
>

export const postOrdersRequest = z.object({
  items: z.array(
    z.object({
      itemId: z.number(),
      price: z.number(),
    })
  ),
})
export type PostOrdersRequest = z.infer<typeof postOrdersRequest>

export const postOrdersResponse = z.object({ uuid: z.string() })
export type PostOrdersResponse = z.infer<typeof postOrdersResponse>

export const getWalletsResponse = z.object({ wallets: z.array(wallet) })
export type GetWalletsResponse = z.infer<typeof getWalletsResponse>

export const deleteWalletsResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})
export type DeleteWalletsResponse = z.infer<typeof deleteWalletsResponse>

export const useStripeRepository = () => {
  const api = useCloudstoreApi()
  return {
    get: {
      async getWallets(): Promise<GetWalletsResponse> {
        return await api.get(`/${versionCode}/${prefix}/wallets`)
      },
    },
    post: {
      async postCreateWalletSecret(): Promise<CreateWalletSecretResponse> {
        return await api.post(`/${versionCode}/${prefix}/create_wallet_secret`)
      },
      async postPaymentStripe(
        params: PostPaymentStripeRequest
      ): Promise<PostPaymentStripeResponse> {
        return await api.post(`/${versionCode}/${prefix}/payment_stripe`, {
          body: params,
        })
      },
      async postOrders(params: PostOrdersRequest): Promise<PostOrdersResponse> {
        return await api.post(`/${versionCode}/${prefix}/orders`, {
          body: params,
        })
      },
    },
    put: {},
    patch: {},
    delete: {
      async deleteWallets(
        paymentMethodId: PaymentMethodId
      ): Promise<DeleteWalletsResponse> {
        return await api.delete(
          `/${versionCode}/${prefix}/wallets/${paymentMethodId}`
        )
      },
    },
  }
}
