import { TemporaryImageData } from '@/models/Temporary'
import { useAssetApi } from '@/composables/repository/useAssetApi'

const prefixPath = '/temporary_uploads'

export const useTemporaryRepository = () => {
  const api = useAssetApi(prefixPath)
  return {
    get: {},
    post: {
      async postTemporaryUpload(file: File): Promise<TemporaryImageData> {
        const formData = new FormData()
        formData.append('file', file)
        // return api.post(`${url}${prefix}`, formData, {
        //   headers: {
        //     Authorization: `Bearer ${idToken}`,
        //   },
        // })
        return await api.post('', { body: formData })
      },
    },
    put: {},
    delete: {},
  }
}
