import { z } from 'zod'
import { useChannelSessionApi } from './useChannelSessionApi'
import {
  positionChannel,
  channelSessionUser,
  channelSession,
  authorization,
} from '@/models/channelSession'
import { createUrlSearchParams } from '@/utils/url'

// ▼▼▼▼ Channel Session APIリクエスト、レスポンス ▼▼▼▼
const commonResponse = z.object({
  status: z.string(),
  message: z.string(),
  userJwt: z.string().optional(),
})

// GET PositionChannels List by Users Search
export const getUserSearchRequest = z.object({
  userCodes: z.array(z.string()),
  limit: z.number().optional(),
  offset: z.number().optional(),
  spatiumCode: z.string().optional(),
  worldCode: z.string().optional(),
})
export const getUserSearchResponse = commonResponse.extend({
  data: z.object({
    positionChannels: z.array(positionChannel),
    channelIds: z.array(z.string()).optional(),
  }),
})

export type GetUserSearchRequest = z.infer<typeof getUserSearchRequest>
export type GetUserSearchResponse = z.infer<typeof getUserSearchResponse>

// GET PositionChannels List in Spatium
export const getSpatiumSearchRequest = z.object({
  filterEmpty: z.boolean().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
})
export const getSpatiumSearchResponse = commonResponse.extend({
  data: z.object({
    positionChannels: z.array(positionChannel),
    channelIds: z.array(z.string()).optional(),
  }),
})
export type GetSpatiumSearchRequest = z.infer<typeof getSpatiumSearchRequest>
export type GetSpatiumSearchResponse = z.infer<typeof getSpatiumSearchResponse>

// Post Create new ChannelSession
export const postCreateChannelSessionResponse = commonResponse.extend({
  data: z.object({
    channelSession,
  }),
  jwt: z.string(),
})
export type PostCreateChannelSessionResponse = z.infer<
  typeof postCreateChannelSessionResponse
>

// Post Create PositionChannel
export const postCreatePositionChannelRequest = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  channelType: z.string().optional(),
})
export const postCreatePositionChannelResponse = commonResponse.extend({
  data: z.object({ positionChannel }),
})
export type PostCreatePositionChannelRequest = z.infer<
  typeof postCreatePositionChannelRequest
>
export type PostCreatePositionChannelResponse = z.infer<
  typeof postCreatePositionChannelResponse
>

// Post Create Guest User
export const postCreateGuestUserRequest = z.object({
  authJwt: z.string(),
})
export const postCreateGuestUserResponse = commonResponse.extend({
  type: z.string(),
})
export type PostCreateGuestUserRequest = z.infer<
  typeof postCreateGuestUserRequest
>
export type PostCreateGuestUserResponse = z.infer<
  typeof postCreateGuestUserResponse
>

// Put Enter new PositionChannel
export const putEnterNewPositionChannelRequest = z.object({
  positionChannelId: z.string(),
  ticketJwt: z.string().optional(),
  authenticateJwt: z.string().optional(),
})
export const putEnterNewPositionChannelResponse = commonResponse.extend({
  data: z.object({
    positionChannel,
    authorization,
  }),
  jwt: z.string(),
})
export type PutEnterNewPositionChannelRequest = z.infer<
  typeof putEnterNewPositionChannelRequest
>
export type PutEnterNewPositionChannelResponse = z.infer<
  typeof putEnterNewPositionChannelResponse
>

// ▲▲▲▲ 各種APIリクエスト、レスポンス ▲▲▲▲

const prefix = ''

export const useChannelSessionRepository = () => {
  const api = useChannelSessionApi()

  return {
    get: {
      /**
       * 指定ユーザーIDが滞在している座標チャンネル一覧を取得する。
       * @param params
       * @returns
       */
      userSearch(params: GetUserSearchRequest): Promise<GetUserSearchResponse> {
        const _params: { [key: string]: string | number | string[] } = {
          ...params,
        }

        // NOTE: userCodes が空の場合は検索できないのでエラーを返す
        if (!params.userCodes.length) throw new Error('userCodes is empty')

        const userCodeQuery = params.userCodes
          .map((code) => `user_codes[]=vketaccount__${code}`)
          .join('&')

        delete _params.userCodes
        const otherQuery = createUrlSearchParams(_params)

        const query = `${userCodeQuery}${otherQuery ? '&' + otherQuery : ''}`
        return api.get(`${prefix}/position_channels/user_exists?${query}`)
      },
      /**
       * 特定の Spatium に紐づく PositionChannel を取得する
       * @param params
       * @returns
       */
      spatiumSearch(
        spatiumCode: string,
        params: GetSpatiumSearchRequest = {}
      ): Promise<GetSpatiumSearchResponse> {
        const query = createUrlSearchParams(params)
        return api.get(
          `${prefix}/spatiums/${spatiumCode}/position_channels${
            query ? '?' + query : ''
          }`
        )
      },
    },
    post: {
      /**
       * ChannelSessionを新規登録する
       * @returns
       */
      createChannelSession(
        jwt: string
      ): Promise<PostCreateChannelSessionResponse> {
        return api.post(`${prefix}/channel_session`, {
          headers: {
            'X-User-Jwt-Session': jwt,
          },
        })
      },
      /**
       * World に紐づいた PositionChannel を新規作成する
       * @param spatiumCode
       * @param worldId
       * @param params
       * @returns
       */
      createPositionChannel(
        userJwt: string,
        channelJwt: string,
        spatiumCode: string,
        worldId: string,
        params: PostCreatePositionChannelRequest = {}
      ): Promise<PostCreatePositionChannelResponse> {
        const url = `${prefix}/spatiums/${spatiumCode}/worlds/${worldId}/position_channels`
        return api.post(url, {
          headers: {
            'X-User-Jwt-Session': userJwt,
            'X-JWT-CHANNEL-SESSION': channelJwt,
          },
          body: params,
        })
      },
      createGuestUser(
        params: PostCreateGuestUserRequest
      ): Promise<PostCreateGuestUserResponse> {
        const url = `${prefix}/auth`
        return api.post(url, {
          body: params,
        })
      },
    },
    put: {
      /**
       * ChannelSession を維持して新しい PositionChannel に入る
       * @param params
       * @returns
       */
      enterNewPositionChannel(
        userJwt: string,
        channelJwt: string,
        params: PutEnterNewPositionChannelRequest
      ): Promise<PutEnterNewPositionChannelResponse> {
        return api.put(`${prefix}/channel_session/position_channel`, {
          body: params,
          headers: {
            'X-User-Jwt-Session': userJwt,
            'X-JWT-CHANNEL-SESSION': channelJwt,
          },
        })
      },
    },
    patch: {},
    delete: {},
  }
}
