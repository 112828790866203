// note: APIの向き先に応じてファイルを新規作成する
import type { InjectionKey } from 'vue'
import type { FetchOptions } from 'ohmyfetch'
import { useOhmyfetch } from './useOhmyfetch'
import { useAuth } from '@/composables/useAuth'

export const useCloudstoreApi = () => {
  const config = useRuntimeConfig()
  const baseURL = config.public.NUXT_ENV_CLOUDSTORE_API_BASE_URL
  const prefixPath = `${config.public.NUXT_ENV_CLOUDSTORE_API_BASE_URL}`
  const { state, getTokens, isGottenMe, logout } = useAuth()
  const fetch = useOhmyfetch({
    baseURL,
    prefixPath,
  })
  const api = fetch.api
  const headers: FetchOptions['headers'] = {
    Accept: 'application/json',
  }

  const defaultFetchOptions = (): FetchOptions => {
    return {
      headers,
      onResponseError: async (ctx) => {
        if (
          process.client &&
          ctx &&
          ctx.response &&
          ctx.response.status === 401
        ) {
          await getTokens()
        }
      },
    }
  }

  // cookieから認証用トークンを取得しなおす
  const refreshIdToken = async () => {
    if (!isGottenMe.value) return
    try {
      await getTokens()
      if (state.value.idToken) {
        headers.Authorization = `Bearer ${state.value.idToken}`
      }
    } catch (e) {
      console.error(e)
      logout()
    }
  }

  return {
    get: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.get(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
    post: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.post(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
    put: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.put(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
    patch: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.patch(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
    delete: async (path: string, fetchOptions: FetchOptions = {}) => {
      await refreshIdToken()
      return api.delete(path, {
        ...defaultFetchOptions(),
        ...fetchOptions,
      })
    },
  }
}

export type CloudstoreApiComposable = ReturnType<typeof useCloudstoreApi>
export const cloudstoreApiInjectionKey: InjectionKey<CloudstoreApiComposable> =
  Symbol('cloudstore-api')
