import { z } from 'zod'
import { useApi } from './useApi'
import { createUrlSearchParams } from '@/utils/url'
import { worldPortal } from '@/models/worldPortal'

/** いいねしたワールドポータル一覧 */
export const getWorldPortalLikesRequest = z.object({
  limit: z.number(),
  offset: z.number(),
})
export type GetWorldPortalLikesRequest = z.infer<
  typeof getWorldPortalLikesRequest
>
export const getWorldPortalLikesResponse = z.object({
  likedWorldPortalCount: z.number(),
  likedWorldPortals: z.array(worldPortal),
})
export type GetWorldPortalLikesResponse = z.infer<
  typeof getWorldPortalLikesResponse
>

const prefix: string = '/user/likes/world_portal'

export const useWorldPortalLikeRepository = () => {
  const api = useApi()
  return {
    get: {
      getWorldPortalLikes(
        limit: number,
        offset: number
      ): Promise<GetWorldPortalLikesResponse> {
        return api.get(`${prefix}?limit=${limit}&offset=${offset}`)
      },
    },
    post: {},
    put: {},
    patch: {},
    delete: {},
  }
}
