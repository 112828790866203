import { z } from 'zod'

export const paypalAccount = z.object({
  email: z.string(),
})
export type PaypalAccount = z.infer<typeof paypalAccount>

export const accountCategory = z.union([
  z.literal('savings'),
  z.literal('current'),
])
export type AccountCategory = z.infer<typeof accountCategory>

export const bankAccount = z.object({
  accountCategory,
  financialInstitutionCode: z.string(),
  sortCode: z.string(),
  accountNumber: z.string(),
  holderName: z.string(),
})
export type BankAccount = z.infer<typeof bankAccount>

export const transferTarget = z.union([
  z.literal('paypal'),
  z.literal('bank'),
  z.literal('no_info'),
])
export type TransferTarget = z.infer<typeof transferTarget>

export const transferStatus = z.union([
  z.literal('awaiting'),
  z.literal('success'),
  z.literal('failed'),
  z.literal('no_transfer'),
])
export type TransferStatus = z.infer<typeof transferStatus>

export const transferHistory = z.object({
  year: z.string(),
  month: z.string(),
  status: transferStatus,
  code: z.string().nullable(),
  reason: z.string().nullable(),
  detail: z.string().nullable(),
  transferPaymentMethod: transferTarget,
  transferAmount: z.number(),
})
export type TransferHistory = z.infer<typeof transferHistory>
