import { z } from 'zod'
import { useApi } from './useApi'
import { profileData } from '@/models/profiles'

const prefix: string = '/user/tutorial'

export const postTutorialCompleteRequest = z.object({
  topPageAvatarId: z.number(),
  presetHousingHomeId: z.number(),
})

export type PostTutorialCompleteRequest = z.infer<
  typeof postTutorialCompleteRequest
>

export const postTutorialCompleteResponse = z.object({
  profile: profileData,
})

export type PostTutorialCompleteResponse = z.infer<
  typeof postTutorialCompleteResponse
>

export const useTutorialRepository = () => {
  const api = useApi()
  return {
    get: {},
    post: {
      async postTutorialComplete(
        params: PostTutorialCompleteRequest
      ): Promise<PostTutorialCompleteResponse> {
        return await api.post(`${prefix}/complete`, {
          body: params,
        })
      },
    },
    put: {},
    patch: {},
    delete: {},
  }
}
