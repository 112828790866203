/* room: ダイレクトメッセージ、チャットルーム */

import { z } from 'zod'
import { iconResponse } from '@/models/file'

/** メッセージルーム */
export const messageRoom = z.object({
  id: z.number(),
  vketId: z.string().optional(),
  name: z.string(),
  icon: iconResponse,
  message: z.string().nullable(),
  messageAt: z.string().nullable(),
  lastReadMessageId: z.number().nullable().optional(),
  unreadCount: z.number().optional(),
  blockStatus: z.boolean().optional(),
  blockedStatus: z.boolean().optional(),
  mutualFollowStatus: z.boolean().optional(),
  sendMessage: z.boolean().optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type MessageRoom = z.infer<typeof messageRoom>

/** メッセージルーム（チェック用） */
export const messageCheckRoom = z.object({
  id: z.number(),
  roomId: z.number().optional(),
  vketId: z.string(),
  name: z.string(),
  icon: iconResponse.pick({ url: true }),
  message: z.string().nullable(),
  messageAt: z.string().nullable(),
  lastReadMessageId: z.number().nullable(),
  blockStatus: z.boolean(),
  blockedStatus: z.boolean(),
  sendMessage: z.boolean(),
  mutualFollowStatus: z.boolean(),
  createdAt: z.string().optional(),
  updatedAt: z.string().optional(),
})
export type MessageCheckRoom = z.infer<typeof messageCheckRoom>

export const messageNoRoom = z.object({ is_room: z.boolean() })
export type MessageNoRoom = z.infer<typeof messageNoRoom>

/** メッセージルーム */
export const messageRoomSearch = z.object({
  id: z.number(),
  name: z.string(),
  icon: iconResponse,
  message: z.string().nullable(),
  messageAt: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type MessageRoomSearch = z.infer<typeof messageRoomSearch>

/** ローカルで作成中のメッセージルーム */
export const tempMessageRoom = messageRoom
  .partial()
  .extend({ vketId: z.string(), name: z.string(), icon: iconResponse })
export type TempMessageRoom = z.infer<typeof tempMessageRoom>

/** メッセージ */
export const message = z.object({
  id: z.number(),
  userId: z.number(),
  vketId: z.string().optional(),
  roomId: z.number(),
  message: z.string(),
  isRead: z.boolean(),
  isHidden: z.boolean(),
  user: z.object({
    id: z.number(),
    vketId: z.string(),
    name: z.string(),
    icon: iconResponse,
  }),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type Message = z.infer<typeof message>

export const messageTargetUser = z.object({
  id: z.number(),
  vketId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  name: z.string(),
  introduction: z.string(),
  icon: iconResponse.pick({ url: true }),
  topPageAvatarId: z.number().nullable(),
  followCount: z.number(),
  followerCount: z.number(),
  followStatus: z.boolean(),
  followerStatus: z.boolean(),
  blockStatus: z.boolean(),
  blockedStatus: z.boolean(),
})
export type MessageTargetUser = z.infer<typeof messageTargetUser>
export const TARGET_TYPE = {
  ALL: 'all',
  MUTUAL_FOLLOW: 'mutual_follow',
} as const
export const targetType = z.enum([TARGET_TYPE.ALL, TARGET_TYPE.MUTUAL_FOLLOW])
export type TargetType = z.infer<typeof targetType>

export const messageSetting = z.object({
  id: z.number(),
  userId: z.number(),
  message: z.boolean(),
  target: targetType,
  platformCode: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type MessageSetting = z.infer<typeof messageSetting>
