import { z } from 'zod'

/** UserJWTのもととなるユーザ情報 */
export const channelSessionUser = z.object({
  uid: z.string(),
  name: z.string(),
  icon: z.string(),
  avatar: z.string().nullable(),
  userType: z.string(),
  expiredAt: z.union([z.instanceof(Date), z.string()]), // date
})
/** UserJWTのもととなるユーザ情報 */
export type ChannelSessionUser = z.infer<typeof channelSessionUser>

/** 座標チャンネル */
export const positionChannel = z.object({
  channelId: z.string(),
  spatiumCode: z.string(),
  worldCode: z.string(),
  channelType: z.string(),
  createUserCode: z.string(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  channelPassword: z.string().nullable(),
  maxPlayerCount: z.number(),
  maxVoicePlayerCount: z.number(),
  allowMultiVoiceChannel: z.boolean(),
  users: z.array(channelSessionUser),
})
/** 座標チャンネル */
export type PositionChannel = z.infer<typeof positionChannel>

/** PlayerJWTのもととなるセッション情報 */
export const channelSession = z.object({
  channelSessionCode: z.string(),
  userCode: z.string(),
  worldCode: z.string().nullable(),
  positionChannelCode: z.string().nullable(),
  voiceChannelCode: z.string().nullable(),
  expiredAt: z.union([z.instanceof(Date), z.string()]), // date
})
/** PlayerJWTのもととなるセッション情報 */
export type ChannelSession = z.infer<typeof channelSession>

/** プレイヤー権限情報 */
export const authorization = z.object({
  userCode: z.string(),
  role: z.string(),
  permission: z.string(),
  forceEntry: z.boolean(),
  attribute: z.string(),
  positionChannelCode: z.string(),
  worldCode: z.string(),
  spatiumCode: z.string(),
  exp: z.string(),
})
/** プレイヤー権限情報 */
export type Authorization = z.infer<typeof authorization>
