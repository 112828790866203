import { $fetch } from 'ohmyfetch'
import type { InjectionKey } from 'vue'
import type { FetchOptions, FetchError } from 'ohmyfetch'
import camelcaseKeys from 'camelcase-keys'
import snakecaseKeys from 'snakecase-keys'

// APIエラーの型チェック
export const isFetchError = (error: unknown): error is FetchError => {
  if (!(error instanceof Error)) return false
  return 'response' in error
}

export const useOhmyfetch = (apiBasePrefix: {
  baseURL: string
  prefixPath: string
}) => {
  // デフォルトオプション
  const defaultFetchOptions: FetchOptions = {
    baseURL: apiBasePrefix.baseURL,
    retry: 2,
    // headers: {},
    onRequest: async (ctx) => {
      if (
        !ctx.options.body ||
        typeof ctx.options.body !== 'object' ||
        // FormDataをsnakecaseKeysに突っ込むと空Objectになるので、append時、snakecaseにしておく
        ctx.options.body instanceof FormData
      ) {
        return
      }
      ctx.options.body = await snakecaseKeys(ctx.options.body, { deep: true })
    },
    // onRequestError: async (ctx) => {},
    onResponse: async (ctx) => {
      if (!ctx.response._data || typeof ctx.response._data !== 'object') {
        return
      }
      ctx.response._data = await camelcaseKeys(ctx.response._data, {
        deep: true,
      })
    },
    // onResponseError: async (ctx) => {},
  }

  const apiFetchFunction = (
    method: string,
    _path: string,
    _options?: Omit<FetchOptions, 'method'>
  ) => {
    return (async (path = _path, opts = _options) => {
      const options = { ...opts, method }
      return await $fetch(apiBasePrefix.prefixPath + path, options)
    })()
  }

  const api = {
    get: (path: string, fetchOptions: FetchOptions = {}) => {
      const methodOptions: FetchOptions = {}
      const options: FetchOptions = {
        ...defaultFetchOptions,
        ...methodOptions,
        ...fetchOptions,
      }
      return apiFetchFunction('GET', path, options)
    },
    post: (path: string, fetchOptions: FetchOptions = {}) => {
      const methodOptions: FetchOptions = {}
      const options = {
        ...defaultFetchOptions,
        ...methodOptions,
        ...fetchOptions,
      }
      return apiFetchFunction('POST', path, options)
    },
    put: (path: string, fetchOptions: FetchOptions = {}) => {
      const methodOptions: FetchOptions = {}
      const options = {
        ...defaultFetchOptions,
        ...methodOptions,
        ...fetchOptions,
      }
      return apiFetchFunction('PUT', path, options)
    },
    patch: (path: string, fetchOptions: FetchOptions = {}) => {
      const methodOptions: FetchOptions = {}
      const options = {
        ...defaultFetchOptions,
        ...methodOptions,
        ...fetchOptions,
      }
      return apiFetchFunction('PATCH', path, options)
    },
    delete: (path: string, fetchOptions: FetchOptions = {}) => {
      const methodOptions: FetchOptions = {}
      const options = {
        ...defaultFetchOptions,
        ...methodOptions,
        ...fetchOptions,
      }
      return apiFetchFunction('DELETE', path, options)
    },
  }

  return {
    api,
  }
}

export type OhmyfetchComposable = ReturnType<typeof useOhmyfetch>

export const apiInjectionKey: InjectionKey<OhmyfetchComposable> =
  Symbol('ohmyfetch')
