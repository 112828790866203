import { z } from 'zod'
import { TARGET_AGE } from '@/utils/constants'

export const SETTING_LABEL = {
  VRM: 'vrm',
  AVATAR_MAKER: 'avatar_maker',
  FURNITURE: 'furniture',
  SDK: 'sdk',
  PLAYGROUND: 'playground',
  ACTIVITY: 'activity',
} as const
export const settingLabel = z.enum([
  SETTING_LABEL.VRM,
  SETTING_LABEL.AVATAR_MAKER,
  SETTING_LABEL.FURNITURE,
  SETTING_LABEL.SDK,
  SETTING_LABEL.PLAYGROUND,
  SETTING_LABEL.ACTIVITY,
])
export type SettingLabel = z.infer<typeof settingLabel>

export const ASSET_TYPE = {
  AVATAR: 'avatar',
  FURNITURE: 'furniture',
  ZIP: 'zip',
} as const

const assetType = z.enum([
  ASSET_TYPE.AVATAR,
  ASSET_TYPE.FURNITURE,
  ASSET_TYPE.ZIP,
])

export type AssetType = z.infer<typeof assetType>

export const uploadStatus = z.enum([
  'waiting',
  'uploaded',
  'analyzing',
  'validating',
  'failed',
  'expired',
])

export const uploadAssetStatus = z.enum([
  'waiting',
  'uploaded',
  'analyzing',
  'validating',
  'failed',
  'expired',
])

export const ASSET_OPTION = {
  uploaded: 'uploaded',
  purchased: 'purchased',
} as const

export type AssetOption = (typeof ASSET_OPTION)[keyof typeof ASSET_OPTION]

/** アセットのカテゴリーリスト */
export const CATEGORY_LIST = ['avatar', 'furniture'] as const
export const category = z.enum(CATEGORY_LIST)
export type Category = z.infer<typeof category>

/**
 * 家具のサブカテゴリーリスト
 * 「床」「天井」「壁」「プリセット」
 */
export const FURNITURE_SUB_CATEGORY_LIST = ['floor', 'ceiling', 'wall'] as const
export const furnitureSubCategory = z.enum(FURNITURE_SUB_CATEGORY_LIST)
export type FurnitureSubCategory = z.infer<typeof furnitureSubCategory>

export const subCategory = furnitureSubCategory
export type SubCateogory = z.infer<typeof subCategory>

export const OBJECT_TYPE = ['object', 'activity'] as const
export const objectType = z.enum(OBJECT_TYPE)
export type ObjectType = z.infer<typeof objectType>

const downloadUrl = z.object({
  url: z.string(),
  expiresOn: z.union([z.instanceof(Date), z.string()]), // date
})

const medium = z.object({
  url: z.string().nullable(),
})

const thumbnail = z.object({
  medium,
  url: z.string().nullable(),
})

const ingameThumbnail = z.object({
  medium,
  url: z.string().nullable(),
})

const animation = z.object({
  url: z.string().nullable(),
})

const assetDetail = z.object({
  motionType: z.string(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]),
  avatarType: z.string(),
  polygonCount: z.number(),
  useVketChanParts: z.boolean(),
  usePaidParts: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
})

const assetZipDetail = z.object({
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
})

const secureDownloadUrls = z.object({
  hrm_png: z.string().nullable().optional(),
  hrm_astc: z.string().nullable().optional(),
  hrm_etc2: z.string().nullable().optional(),
})

const assetFurnitureDetail = z.object({
  objectType,
  subType: furnitureSubCategory,
  jsonPath: z.string().optional(),
  polygonCount: z.number(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
})

export const assetVersionData = z.object({
  id: z.number(),
  uuid: z.string(),
  version: z.string(),
  thumbnail,
  ingameThumbnail,
  animation,
  downloadUrl: downloadUrl.nullable().optional(),
  uploadStatus,
  assetAvatarDetail: assetDetail.nullable().optional(),
  assetZipDetail: assetZipDetail.optional(),
  secureDownloadUrls: secureDownloadUrls.nullable().optional(),
  assetFurnitureDetail: assetFurnitureDetail.optional(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
})

export type AssetVersionData = z.infer<typeof assetVersionData>

export const userData = z.object({
  id: z.number(),
  nameEn: z.string().nullish(),
  nameJa: z.string().nullish(),
  vketId: z.string(),
})

export type UserData = z.infer<typeof userData>

export const assetData = z.object({
  id: z.number(),
  userId: z.number(),
  assetSettingId: z.number(),
  assetType,
  name: z.string(),
  description: z.string(),
  deleted: z.boolean(),
  published: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date,
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date,
})
export type AssetData = z.infer<typeof assetData>

// 自身の所持しているasset情報
export const assetDetailData = z.object({
  id: z.number(),
  userId: z.number(),
  assetSettingId: z.number(),
  settingLabel,
  assetType,
  name: z.string(),
  description: z.string(),
  deleted: z.boolean(),
  published: z.boolean(),
  latestVersion: assetVersionData.optional(),
  assetVersions: z.array(assetVersionData).optional(),
  user: userData.optional(),
  createdAt: z.union([z.instanceof(Date), z.string()]), // date,,
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date,,
})
export type AssetDetailData = z.infer<typeof assetDetailData>
