import { z } from 'zod'
import { useApi } from './useApi'

// models
import { categoryType, newsItem } from '@/models/news'

// types
export const getNewsRequest = z.object({
  limit: z.number(),
  offset: z.number(),
  category: categoryType.optional(),
})
export type GetNewsRequest = z.infer<typeof getNewsRequest>
export const getNewsResponse = z.object({
  news: z.array(newsItem),
  newsCount: z.number(),
})
export type GetNewsResponse = z.infer<typeof getNewsResponse>

const prefix = '/news'

export const useNewsRepository = () => {
  const api = useApi()

  return {
    get: {
      getNews(params: GetNewsRequest): Promise<GetNewsResponse> {
        return api.get(`${prefix}`, { params })
      },
    },
    post: {},
    put: {},
    patch: {},
    delete: {},
  }
}
