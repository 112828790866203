import type { InjectionKey } from 'vue'

import { useApi } from './useApi'

import {
  Notification,
  GetNotificationRequest,
  GetNotificationResponse,
  GetNotificationSummaryResponse,
  PatchNotificationReadRequest,
  PatchNotificationReadResponse,
  PatchNotificationAllReadRequest,
  PatchNotificationAllReadResponse,
} from '@/models/notification'

const prefix = '/notifications'

export const useNotificationRepository = () => {
  const api = useApi()

  return {
    get: {
      async getNotifications(
        getData: GetNotificationRequest
      ): Promise<GetNotificationResponse> {
        const query =
          getData.limit !== undefined && getData.offset !== undefined
            ? `?limit=${getData.limit}&offset=${getData.offset}`
            : ''
        return await api.get(`${prefix}${query}`)
      },
      async getNotificationSummary(): Promise<GetNotificationSummaryResponse> {
        return await api.get(`${prefix}/summary`)
      },
    },
    post: {},
    put: {},
    patch: {
      async patchNotificationRead(
        patchData: PatchNotificationReadRequest
      ): Promise<PatchNotificationReadResponse> {
        return await api.patch(`${prefix}/${patchData.id}`)
      },
      async patchNotificationAllRead(
        patchData: PatchNotificationAllReadRequest
      ): Promise<PatchNotificationAllReadResponse> {
        return await api.patch(`${prefix}/read`, { body: patchData })
      },
    },
    delete: {},
  }
}

export default useNotificationRepository

export type NotificationRepositoryComposable = ReturnType<
  typeof useNotificationRepository
>
export const NotificationRepositoryInjectionKey: InjectionKey<NotificationRepositoryComposable> =
  Symbol('useNotificationRepository')
