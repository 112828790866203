import { z } from 'zod'
import { useCloudstoreApi } from '@/composables/repository/useCloudstoreApi'
import {
  bankAccount,
  paypalAccount,
  BankAccount,
  transferHistory,
} from '@/models/transfer'

const versionCode = 'v1'

//* GET *//
// [販売者用] 振込情報取得

export const getTransferAccountResponse = z.object({
  paypal: paypalAccount.nullable(),
  bankAccount: bankAccount.nullable(),
})
export type GetTransferAccountResponse = z.infer<
  typeof getTransferAccountResponse
>

//* GET *//
// [販売者用] 振込状況一覧
export const getTransferHistoriesRequest = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
})
export type GetTransferHistoriesRequest = z.infer<
  typeof getTransferHistoriesRequest
>

export const getTransferHistoriesResponse = z.object({
  transferHistoryCount: z.number(),
  transferHistories: z.array(transferHistory),
  scheduledAmount: z.number(),
})
export type GetTransferHistoriesResponse = z.infer<
  typeof getTransferHistoriesResponse
>

// 銀行口座情報登録更新
export const postBankAccountResponse = z
  .object({
    bankAccount,
  })
  .nullable()
export type PostBankAccountResponse = z.infer<typeof postBankAccountResponse>

// PayPal情報登録更新
export const postPaypalRequest = z.object({
  email: z.string(),
})
export type PostPaypalRequest = z.infer<typeof postPaypalRequest>
export const postPaypalResponse = z.object({
  paypal: z.object({
    email: z.string(),
  }),
})
export type PostPaypalResponse = z.infer<typeof postPaypalResponse>

export const useTransferRepository = () => {
  const api = useCloudstoreApi()

  return {
    get: {
      async getTransferAccount(): Promise<GetTransferAccountResponse> {
        return await api.get(`/${versionCode}/transfer_account`)
      },
      async getTransferHistories(
        params: GetTransferHistoriesRequest
      ): Promise<GetTransferHistoriesResponse> {
        const urlSearchParam = new URLSearchParams()
        if (params.limit) urlSearchParam.set('limit', `${params.limit}`)
        if (params.offset) urlSearchParam.set('offset', `${params.offset}`)

        const paramString = urlSearchParam.toString()
        const query = paramString !== '' ? `?${paramString}` : ''
        return await api.get(`/${versionCode}/transfer_histories${query}`)
      },
    },
    post: {
      async postBankAccount(
        params: BankAccount
      ): Promise<PostBankAccountResponse> {
        return await api.post(`/${versionCode}/bank_account`, {
          body: params,
        })
      },
      async postPaypal(params: PostPaypalRequest): Promise<PostPaypalResponse> {
        return await api.post(`/${versionCode}/paypal`, {
          body: params,
        })
      },
    },
    put: {},
    patch: {},
    delete: {},
  }
}
