import { z } from 'zod'
import { useCloudstoreApi } from '@/composables/repository/useCloudstoreApi'

// models
import {
  itemPurchased,
  item,
  orderItem,
  orderItemHistory,
  orderItemDetail,
  orderFreeItemHistoryDetail,
  GetItemsRequest,
  GetItemsResponse,
  GetItemsPublishedRecentRequest,
  GetItemsPublishedRecentResponse,
  categoryType,
  SORT_TYPE,
  subCategoryType,
  accessibleStatusType,
  objectType as cloudStoreObjectType,
  forcedPrivateItem,
  mypageItem,
} from '@/models/cloudstore'
import { createUrlSearchParams } from '@/utils/url'
import { MyVketEvent } from '@/models/myVketEvent'
import { TARGET_AGE } from '@/utils/constants'
import { objectType } from '@/models/asset'

// ▼▼▼▼▼▼  API用request、response定義  ▼▼▼▼▼▼
// [購入者用] 公開商品一覧取得
export const getItemsPublishedRequest = z.object({
  boothMakerRecommended: z.boolean().optional(),
  category: categoryType.optional(),
  subCategory: subCategoryType.optional(),
  hasThumbnailUrl: z.boolean().optional(),
  includeFreeItems: z.boolean().optional(),
  isOfficial: z.boolean().optional(),
  keyword: z.string().optional(),
  limit: z.number().optional(),
  objectTypes: z.array(cloudStoreObjectType).optional(),
  offset: z.number().optional(),
  sort: z
    .enum([
      SORT_TYPE.LIKES,
      SORT_TYPE.NEWER,
      SORT_TYPE.OLDER,
      SORT_TYPE.POPULAR,
      SORT_TYPE.PURCHASES,
      SORT_TYPE.PRICE_ASC,
      SORT_TYPE.PRICE_DESC,
    ])
    .optional(),
  useVketChanParts: z.boolean().optional(),
  vketId: z.string().optional(),
})
export type GetItemsPublishedRequest = z.infer<typeof getItemsPublishedRequest>
export const getItemsPublishedRequestWithPager = z.object({
  page: z.number(),
  limit: z.number().optional(),
  options: z.object({
    boothMakerRecommended: z.boolean().optional(),
    category: categoryType.optional(),
    subCategory: subCategoryType.optional(),
    includeFreeItems: z.boolean().optional(),
    isOfficial: z.boolean().optional(),
    keyword: z.string().optional(),
    isActivity: z.boolean().optional(),
    sort: z
      .enum([
        SORT_TYPE.LIKES,
        SORT_TYPE.NEWER,
        SORT_TYPE.OLDER,
        SORT_TYPE.POPULAR,
        SORT_TYPE.PURCHASES,
        SORT_TYPE.PRICE_ASC,
        SORT_TYPE.PRICE_DESC,
      ])
      .optional(),
    useVketChanParts: z.boolean().optional(),
    vketId: z.string().optional(),
  }),
})
export type GetItemsPublishedRequestWithPager = z.infer<
  typeof getItemsPublishedRequestWithPager
>
export const getItemsPublishedResponse = z.object({
  items: z.array(itemPurchased),
  itemCount: z.number(),
})
export type GetItemsPublishedResponse = z.infer<
  typeof getItemsPublishedResponse
>

// [販売者用] 販売商品登録
export const postItemRequest = z.object({
  name: z.string(),
  nameEn: z.string().optional(),
  assetId: z.number(),
  category: categoryType,
  subCategory: subCategoryType.optional(),
  objectType: objectType.optional(),
  subThumbnails: z.array(z.union([z.instanceof(File), z.string()])),
  youtubeUrl: z.string().optional(),
  description: z.string(),
  descriptionEn: z.string().optional(),
  termsOfUse: z.string().optional(),
  termsOfUseEn: z.string().optional(),
  targetAge: z.enum([TARGET_AGE.allAges, TARGET_AGE.r18]).optional(),
  accessibleStatus: accessibleStatusType,
  price: z.number().optional(),
  discountRate: z.number().optional(),
  secondaryUse: z.boolean().optional(),
  commercialUse: z.boolean().optional(),
  isPaid: z.boolean().optional(),
  canPurchase: z.boolean().optional(),
})
export const postItemResponse = z.object({
  item,
})
export type PostItemRequest = z.infer<typeof postItemRequest>
export type PostItemResponse = z.infer<typeof postItemResponse>

// [販売者用] 商品データ更新
export const putItemRequest = z.object({
  id: z.number(),
  item: postItemRequest.omit({ assetId: true }).partial(),
})
export const putItemResponse = z.object({
  item,
})
export type PutItemRequest = z.infer<typeof putItemRequest>
export type PutItemResponse = z.infer<typeof putItemResponse>

// [販売者用] 商品データ更新
export const patchItemRequest = z.object({
  id: z.number(),
  item: postItemRequest.omit({ assetId: true }).partial(),
})
export const patchItemResponse = z.object({
  item,
})
export type PatchItemRequest = z.infer<typeof patchItemRequest>
export type PatchItemResponse = z.infer<typeof patchItemResponse>
// [購入者用] 公開商品データ詳細取得
export const getItemPublishedDetailRequest = z.object({
  id: z.number(),
})

export type GetItemPublishedDetailRequest = z.infer<
  typeof getItemPublishedDetailRequest
>

export const getItemPublishedDetailResponse = z.object({
  item,
  purchased: z.boolean(),
  liked: z.boolean(),
})

export type GetItemPublishedDetailResponse = z.infer<
  typeof getItemPublishedDetailResponse
>

// [購入者用] 無償商品入手
export const postItemPurchaseRequest = z.object({
  id: z.string(),
})
export const postItemPurchaseResponse = z.object({
  item,
})
export type PostItemPurchaseRequest = z.infer<typeof postItemPurchaseRequest>
export type PostItemPurchaseResponse = z.infer<typeof postItemPurchaseResponse>

// 商品データ一括取得
export const postItemListRequest = z.object({
  itemIds: z.array(z.number()),
})
export type PostItemListRequest = z.infer<typeof postItemListRequest>
export const postItemListResponse = z.object({
  items: z.array(itemPurchased),
})
export type PostItemListResponse = z.infer<typeof postItemListResponse>

// [購入者用] 商品にいいね
export const putItemLikeRequest = z.object({
  id: z.string(),
})
export const putItemLikeResponse = z.object({
  item,
  liked: z.boolean(),
})
export type PutItemLikeRequest = z.infer<typeof putItemLikeRequest>
export type PutItemLikeResponse = z.infer<typeof putItemLikeResponse>

// [販売者用] 自分の登録商品データ詳細
export const getItemDetailRequest = z.object({
  id: z.number(),
})
export const getItemDetailResponse = z.object({
  item,
})
export type GetItemDetailRequest = z.infer<typeof getItemDetailRequest>
export type GetItemDetailResponse = z.infer<typeof getItemDetailResponse>

// [販売者用] 入手・購入もしくはアップロードした商品データ詳細
export const getItemByAssetIdResponse = z.object({
  item,
})
export type GetItemByAssetIdResponse = z.infer<typeof getItemByAssetIdResponse>

// [マイページ] 入手・購入した商品
export const getMypagePurchasedItemsRequest = z.object({
  category: categoryType,
  limit: z.number().optional(),
  offset: z.number().optional(),
})
export const getMypagePurchasedItemsResponse = z.object({
  items: z.array(mypageItem),
  itemCount: z.number(),
})
export type GetMypagePurchasedItemsRequest = z.infer<
  typeof getMypagePurchasedItemsRequest
>
export type GetMypagePurchasedItemsResponse = z.infer<
  typeof getMypagePurchasedItemsResponse
>

// [マイページ]アップロードした作品
export const getMypageUploadItemRequest = z.object({
  category: categoryType,
  limit: z.number().optional(),
  offset: z.number().optional(),
})
export const getMypageUploadItemResponse = z.object({
  items: z.array(mypageItem),
  itemCount: z.number(),
})
export type GetMypageUploadItemRequest = z.infer<
  typeof getMypageUploadItemRequest
>
export type GetMypageUploadItemResponse = z.infer<
  typeof getMypageUploadItemResponse
>

// [トップページ]人気アイテム一覧取得
export const getItemsPopularRequest = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
  hasThumbnailUrl: z.boolean().optional(),
})
export const getItemsPopularResponse = z.object({
  items: z.array(itemPurchased),
  itemCount: z.number(),
})
export type GetItemsPopularRequest = z.infer<typeof getItemsPopularRequest>
export type GetItemsPopularResponse = z.infer<typeof getItemsPopularResponse>

// 購入履歴一覧取得
export const getPurchaseHistoriesRequest = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
})

export const getPurchaseHistoriesResponse = z.object({
  purchaseHistories: z.array(orderItemHistory),
  historyCount: z.number(),
})

export type GetPurchaseHistoriesRequest = z.infer<
  typeof getPurchaseHistoriesRequest
>
export type GetPurchaseHistoriesResponse = z.infer<
  typeof getPurchaseHistoriesResponse
>

// 購入履歴詳細取得
export const getPurchaseHistoriesDetailRequest = z.object({
  id: z.string(),
  limit: z.number().optional(),
  offset: z.number().optional(),
})

export const getPurchaseHistoriesDetailResponse = z.object({
  order: orderItemDetail,
})

export type GetPurchaseHistoriesDetailRequest = z.infer<
  typeof getPurchaseHistoriesDetailRequest
>
export type GetPurchaseHistoriesDetailResponse = z.infer<
  typeof getPurchaseHistoriesDetailResponse
>

// 無償商品購入履歴詳細
export const getPurchaseHistoriesFreeDetailRequest = z.object({
  id: z.string(),
})

export const getPurchaseHistoriesFreeDetailResponse = z.object({
  order: orderFreeItemHistoryDetail,
})

export type GetPurchaseHistoriesFreeDetailRequest = z.infer<
  typeof getPurchaseHistoriesFreeDetailRequest
>
export type GetPurchaseHistoriesFreeDetailResponse = z.infer<
  typeof getPurchaseHistoriesFreeDetailResponse
>

// 強制非公開商品一覧取得
export const getForcedPrivateItemsResponse = z.object({
  forcedPrivateItems: z.array(forcedPrivateItem),
  forcedPrivateItemExists: z.boolean(),
})
export type GetForcedPrivateItemsResponse = z.infer<
  typeof getForcedPrivateItemsResponse
>

//* DELETE *//
// [購入者用] 商品にいいね取り消し
export const deleteItemLikeRequest = z.object({
  id: z.string(),
})
export const deleteItemLikeResponse = z.object({
  item,
  liked: z.boolean(),
})
export type DeleteItemLikeRequest = z.infer<typeof deleteItemLikeRequest>
export type DeleteItemLikeResponse = z.infer<typeof deleteItemLikeResponse>
// ▲▲▲▲▲▲  API用request、response定義  ▲▲▲▲▲▲

const versionCode = 'v1'
const prefix = 'items'

export const getItemsLikesResponse = z.object({
  itemCount: z.number(),
  items: z.array(itemPurchased),
})

export type GetItemsLikesResponse = z.infer<typeof getItemsLikesResponse>

export const getEventItemsResponse = z.object({
  items: z.array(itemPurchased),
  itemCount: z.number(),
})

export type GetEventItemsResponse = z.infer<typeof getEventItemsResponse>

export const useCloudStoreRepository = () => {
  const api = useCloudstoreApi()

  return {
    get: {
      async getItems(params: GetItemsRequest): Promise<GetItemsResponse> {
        const query =
          params.limit !== undefined && params.offset !== undefined
            ? `?limit=${params.limit}&offset=${params.offset}`
            : ''
        return await api.get(`/${versionCode}/${prefix}${query}`)
      },
      async getItemDetail(
        id: GetItemDetailRequest
      ): Promise<GetItemDetailResponse> {
        return await api.get(`/${versionCode}/${prefix}/${id}`)
      },
      async getItemsPublished(
        params: GetItemsPublishedRequest
      ): Promise<GetItemsPublishedResponse> {
        const queryContent = {
          booth_maker_recommended: params.boothMakerRecommended,
          category: params.category,
          subCategory: params.subCategory,
          has_thumbnail_url: params.hasThumbnailUrl,
          include_free_items: params.includeFreeItems,
          is_official: params.isOfficial,
          keyword: params.keyword,
          limit: params.limit,
          offset: params.offset,
          sort: params.sort,
          use_vket_chan_parts: params.useVketChanParts,
          vket_id: params.vketId,
          object_types: params.objectTypes,
        }

        const query = createUrlSearchParams(queryContent)
        return await api.get(
          `/${versionCode}/${prefix}/published?${query.toString()}`
        )
      },
      async getItemsPublishedRecent(
        params: GetItemsPublishedRecentRequest
      ): Promise<GetItemsPublishedRecentResponse> {
        const query =
          params.limit !== undefined && params.offset !== undefined
            ? `?limit=${params.limit}&offset=${params.offset}`
            : ''
        return await api.get(
          `/${versionCode}/${prefix}/published/recent${query}`
        )
      },
      async getItemPublishedDetail(
        params: GetItemPublishedDetailRequest
      ): Promise<GetItemPublishedDetailResponse> {
        return await api.get(`/${versionCode}/${prefix}/published/${params.id}`)
      },
      async getItemsLikes(
        params: GetItemsRequest
      ): Promise<GetItemsLikesResponse> {
        const { limit, offset } = params
        return await api.get(`/${versionCode}/${prefix}/likes`, {
          params: { limit, offset },
        })
      },
      async getEventItems(
        eventId: MyVketEvent['id']
      ): Promise<GetEventItemsResponse> {
        return await api.get(`/${versionCode}/${prefix}/events/${eventId}`)
      },
      async getItemByAssetId(
        assetId: number
      ): Promise<GetItemByAssetIdResponse> {
        return await api.get(`/${versionCode}/${prefix}/assets/${assetId}`)
      },
      async getMypagePurchasedItems(
        params: GetMypagePurchasedItemsRequest
      ): Promise<GetMypagePurchasedItemsResponse> {
        return await api.get('/mypage/get_items', {
          params,
        })
      },
      async getMypageUploadItem(
        params: GetMypageUploadItemRequest
      ): Promise<GetMypageUploadItemResponse> {
        return await api.get(`/mypage/upload_items`, {
          params,
        })
      },
      async getItemsPopular(
        params: GetItemsPopularRequest
      ): Promise<GetItemsPopularResponse> {
        const queryContent = {
          has_thumbnail_url: params.hasThumbnailUrl,
          limit: params.limit,
          offset: params.offset,
        }

        const query = createUrlSearchParams(queryContent)
        return await api.get(
          `/${versionCode}/${prefix}/popular?${query.toString()}`
        )
      },
      async getPurchaseHistories(
        params: GetPurchaseHistoriesRequest
      ): Promise<GetPurchaseHistoriesResponse> {
        return await api.get(`/${versionCode}/purchase_histories`, {
          params,
        })
      },
      async getPurchaseHistoriesDetail(
        params: GetPurchaseHistoriesDetailRequest
      ): Promise<GetPurchaseHistoriesDetailResponse> {
        const { id, limit, offset } = params
        return await api.get(`/${versionCode}/purchase_histories/${id}`, {
          params: { limit, offset },
        })
      },
      async getPurchaseHistoriesFreeDetail(
        params: GetPurchaseHistoriesFreeDetailRequest
      ): Promise<GetPurchaseHistoriesFreeDetailResponse> {
        return await api.get(
          `/${versionCode}/purchase_histories/free_item/${params.id}`
        )
      },
      async getItemsForcedPrivate(): Promise<GetForcedPrivateItemsResponse> {
        return await api.get(`/${versionCode}/${prefix}/forced_private`)
      },
    },
    post: {
      async postItem(params: PostItemRequest): Promise<PostItemResponse> {
        const formData = objectToFormData(params)
        return await api.post(`/${versionCode}/${prefix}`, {
          body: formData,
        })
      },
      async postItemList(
        params: PostItemListRequest
      ): Promise<PostItemListResponse> {
        return await api.post(`/${versionCode}/${prefix}/list`, {
          body: params,
        })
      },
      async postItemPurchase(
        params: PostItemPurchaseRequest
      ): Promise<PostItemPurchaseResponse> {
        return await api.post(`/${versionCode}/${prefix}/${params.id}/purchase`)
      },
    },
    put: {
      async putItem({ id, item }: PutItemRequest): Promise<PutItemResponse> {
        const formData = objectToFormData(item)
        return await api.put(`/${versionCode}/${prefix}/${id}`, {
          body: formData,
        })
      },
      async putItemLike(
        params: PutItemLikeRequest
      ): Promise<PutItemLikeResponse> {
        return await api.put(`/${versionCode}/${prefix}/${params.id}/like`)
      },
    },
    patch: {
      async patchItem({
        id,
        item,
      }: PatchItemRequest): Promise<PatchItemResponse> {
        const formData = objectToFormData(item)
        return await api.patch(`/${versionCode}/${prefix}/${id}`, {
          body: formData,
        })
      },
    },
    delete: {
      async deleteItemLike(
        params: DeleteItemLikeRequest
      ): Promise<DeleteItemLikeResponse> {
        return await api.delete(`/${versionCode}/${prefix}/${params.id}/like`)
      },
    },
  }
}
