import type { InjectionKey } from 'vue'

import { useApi } from './useApi'

import {
  GetFollowersRequest,
  GetFollowersResponse,
  GetFollowingsRequest,
  GetFollowingsResponse,
  PostFollowRequest,
  PostFollowResponse,
  DeleteFollowRequest,
  DeleteFollowResponse,
  GetMutualFollowingsRequest,
  GetMutualFollowingsResponse,
} from '@/models/relationship'

const prefix = '/user/relationships'

export const useRelationshipRepository = () => {
  const api = useApi()

  return {
    get: {
      async getFollowings(
        getData: GetFollowingsRequest
      ): Promise<GetFollowingsResponse> {
        const { vketId, limit, offset, name } = getData
        return await api.get(`${prefix}/${vketId}/followings`, {
          params: { limit, offset, name },
        })
      },
      async getFollowers(
        getData: GetFollowersRequest
      ): Promise<GetFollowersResponse> {
        const { vketId, limit, offset, name } = getData
        return await api.get(`${prefix}/${vketId}/followers`, {
          params: { limit, offset, name },
        })
      },
      async getMutualFollowings(
        getData: GetMutualFollowingsRequest
      ): Promise<GetMutualFollowingsResponse> {
        const { name, limit, offset } = getData
        return await api.get(`${prefix}/mutual_followings`, {
          params: { name, limit, offset },
        })
      },
    },
    post: {
      async postFollow(
        postData: PostFollowRequest
      ): Promise<PostFollowResponse> {
        return await api.post(`${prefix}`, { body: postData })
      },
    },
    put: {},
    patch: {},
    delete: {
      async deleteFollow(
        deleteData: DeleteFollowRequest
      ): Promise<DeleteFollowResponse> {
        return await api.delete(`${prefix}/${deleteData.vketId}`)
      },
    },
  }
}

export default useRelationshipRepository

export type RelationshipRepositoryComposable = ReturnType<
  typeof useRelationshipRepository
>
export const RelationshipRepositoryInjectionKey: InjectionKey<RelationshipRepositoryComposable> =
  Symbol('relationshipRepositoryComposable')
