import { z } from 'zod'

import { useApi } from './useApi'

// models
import { deviceType, pushSetting } from '@/models/pushSetting'
import { successMessage } from '@/models/successMessage'
// modules
import { createUrlSearchParams } from '@/utils/url'

const prefix: string = '/user'

// Webプッシュ用デバイストークン登録
export const postPushTokensRequest = z.object({
  token: z.string(),
  deviceType,
})
export const postPushTokensResponse = successMessage
export type PostPushTokensRequest = z.infer<typeof postPushTokensRequest>
export type PostPushTokensResponse = z.infer<typeof postPushTokensResponse>

// Webプッシュ通知設定取得
export const getPushSettingsResponse = z.object({
  pushSetting,
})
export type GetPushSettingsResponse = z.infer<typeof getPushSettingsResponse>

// Webプッシュ通知設定更新
export const postPushSettingsRequest = pushSetting.pick({
  notification: true,
  news: true,
  event: true,
  like: true,
  sns: true,
  message: true,
  room: true,
  present: true,
  store: true,
})
export const postPushSettingsResponse = z.object({
  pushSetting,
})
export type PostPushSettingsRequest = z.infer<typeof postPushSettingsRequest>
export type PostPushSettingsResponse = z.infer<typeof postPushSettingsResponse>

export const useWebPushRepository = () => {
  const api = useApi()
  return {
    get: {
      getPushSettings: (): Promise<GetPushSettingsResponse> =>
        api.get(`${prefix}/push_settings`),
    },
    post: {
      postPushTokens: (
        params: PostPushTokensRequest
      ): Promise<PostPushTokensResponse> =>
        api.post(`${prefix}/push_tokens`, {
          body: params,
        }),
      postPushSettings: (
        params: PostPushSettingsRequest
      ): Promise<PostPushSettingsResponse> =>
        api.post(`${prefix}/push_settings`, {
          body: params,
        }),
    },
    put: {},
    patch: {},
    delete: {},
  }
}
