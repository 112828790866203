import { z } from 'zod'
import { useCloudstoreApi } from '@/composables/repository/useCloudstoreApi'
import {
  orderItem,
  salesByItem,
  salesByPeriod,
  salesSummary,
} from '@/models/sales'

const versionCode = 'v1'

//* GET *//
// [販売者用] 総売上情報取得
export const getSalesSummaryResponse = z.object({
  summary: salesSummary,
})
export type GetSalesSummaryResponse = z.infer<typeof getSalesSummaryResponse>

//* GET *//
// [販売者用] 売上履歴情報取得（月別/年別）
export const getYearlySalesHistoryRequest = z.object({
  // itemId: z.string().optional(), // 2024/03/27 現在未使用
  /** YYYY形式 */
  year: z.string(),
})
export type GetYearlySalesHistoryRequest = z.infer<
  typeof getYearlySalesHistoryRequest
>

export const getMonthlySalesHistoryRequest = z.object({
  // itemId: z.string().optional(), // 2024/03/27 現在未使用
  /** YYYYMM形式 */
  month: z.string(),
})
export type GetMonthlySalesHistoryRequest = z.infer<
  typeof getMonthlySalesHistoryRequest
>

type GetSalesHistoryRequest =
  | GetYearlySalesHistoryRequest
  | GetMonthlySalesHistoryRequest

export const getSalesHistoryResponse = z.object({
  summary: salesSummary,
  byPeriod: z.array(salesByPeriod),
  byItem: z.array(salesByItem),
})
export type GetSalesHistoryResponse = z.infer<typeof getSalesHistoryResponse>

//* GET *//
// [販売者用] 売上履歴情報取得（日別内訳）
export const getDailySalesHistoryRequest = z.object({
  /** YYYYMMDD形式 */
  date: z.string(),
  // itemId: z.string().optional(), // 2024/03/27 現在未使用
})

export type GetDailySalesHistoryRequest = z.infer<
  typeof getDailySalesHistoryRequest
>

export const getDailySalesHistoryResponse = z.object({
  summary: salesSummary,
  histories: z.array(orderItem),
})

export type GetDailySalesHistoryResponse = z.infer<
  typeof getDailySalesHistoryResponse
>

export const useSalesRepository = () => {
  const api = useCloudstoreApi()

  return {
    get: {
      async getSalesSummary(): Promise<GetSalesSummaryResponse> {
        return await api.get(`/${versionCode}/sales_summary`)
      },
      async getSalesHistory(
        params: GetSalesHistoryRequest
      ): Promise<GetSalesHistoryResponse> {
        const urlSearchParam = new URLSearchParams(params).toString()
        const query = urlSearchParam !== '' ? `?${urlSearchParam}` : ''
        return await api.get(`/${versionCode}/sales_histories${query}`)
      },
      async getDailySalesHistory(
        params: GetDailySalesHistoryRequest
      ): Promise<GetDailySalesHistoryResponse> {
        return await api.get(`/${versionCode}/sales_histories/${params.date}`)
      },
    },
    post: {},
    put: {},
    patch: {},
    delete: {},
  }
}
