import { z } from 'zod'
import { useCloudstoreApi } from '@/composables/repository/useCloudstoreApi'
import { order } from '@/models/cloudstore'

const versionCode = 'v1'
const prefix = 'payment/paypal'

export const postCreatePaypalOrderIdRequest = z.object({
  items: z.array(
    z.object({
      itemId: z.number(),
      price: z.number(),
    })
  ),
})
export type PostCreatePaypalOrderIdRequest = z.infer<
  typeof postCreatePaypalOrderIdRequest
>

export const postCreatePaypalOrderIdResponse = z.object({
  orderId: z.string(),
  uuid: z.string(),
})
export type PostCreatePaypalOrderIdResponse = z.infer<
  typeof postCreatePaypalOrderIdResponse
>

export const postCaptureRequest = z.object({
  uuid: z.string(),
  orderId: z.string(),
})
export type PostCaptureRequest = z.infer<typeof postCaptureRequest>

export const postCaptureResponse = z.object({
  order,
})
export type PostCaptureResponse = z.infer<typeof postCaptureResponse>

export const usePaypalRepository = () => {
  const api = useCloudstoreApi()
  return {
    get: {},
    post: {
      async postCreatePaypalOrderId(
        params: PostCreatePaypalOrderIdRequest
      ): Promise<PostCreatePaypalOrderIdResponse> {
        return await api.post(
          `/${versionCode}/${prefix}/create_paypal_order_id`,
          {
            body: params,
          }
        )
      },
      async postCapture(
        params: PostCaptureRequest
      ): Promise<PostCaptureResponse> {
        return await api.post(`/${versionCode}/${prefix}/capture`, {
          body: params,
        })
      },
    },
    put: {},
    patch: {},
    delete: {},
  }
}
