import { z } from 'zod'
import { iconResponse } from '@/models/file'

export const MY_VKET_EVENT_USER_TYPE = {
  ALL: 'all',
  MUTUAL_FOLLOW_ONLY: 'mutual_follow_only',
} as const

export const MY_VKET_EVENT_FILTER = {
  PICKUP: 'pickup',
  COMMING_SOON: 'comming_soon',
  POPULAR: 'popular',
  OFFICIAL: 'official',
} as const

export const PARTICIPATING_MY_VKET_EVENT = {
  ALL: 'all',
  CREATED: 'created',
  OTHERS: 'others',
  MUTUAL_FOLLOW: 'mutual_follow',
} as const

export const myVketEventUserType = z.enum([
  MY_VKET_EVENT_USER_TYPE.ALL, // 全ユーザー
  MY_VKET_EVENT_USER_TYPE.MUTUAL_FOLLOW_ONLY, // 相互フォローのみ
])

export const myVketEventsFilter = z.enum([
  MY_VKET_EVENT_FILTER.PICKUP,
  MY_VKET_EVENT_FILTER.COMMING_SOON,
  MY_VKET_EVENT_FILTER.POPULAR,
  MY_VKET_EVENT_FILTER.OFFICIAL,
])

export const participatingMyVketEventFilter = z.enum([
  PARTICIPATING_MY_VKET_EVENT.ALL,
  PARTICIPATING_MY_VKET_EVENT.CREATED,
  PARTICIPATING_MY_VKET_EVENT.OTHERS,
  PARTICIPATING_MY_VKET_EVENT.MUTUAL_FOLLOW,
])

// コンテンツタイプ関連
export const CONTENT_TYPE = {
  OFFICIAL: 'official',
  USER: 'user',
  PARTICIPATION: 'participation',
  LIKES: 'likes',
} as const

export const contentType = z.enum([
  CONTENT_TYPE.OFFICIAL,
  CONTENT_TYPE.USER,
  CONTENT_TYPE.PARTICIPATION,
  CONTENT_TYPE.LIKES,
])

export type ContentType = z.infer<typeof contentType>

export const myVketEventContentTypeData = z.object({
  value: contentType,
  label: z.string(),
  labelEn: z.string(),
})

export type MyVketEventContentTypeData = z.infer<
  typeof myVketEventContentTypeData
>

// 料金タイプ関連
export const PRICE_TYPE = {
  FREE: 'free',
  PAID: 'paid',
} as const

export const priceType = z.enum([PRICE_TYPE.FREE, PRICE_TYPE.PAID])

export const myVketEventPriceTypeData = z.object({
  value: priceType,
  label: z.string(),
  labelEn: z.string(),
})

export type MyVketEventPriceTypeData = z.infer<typeof myVketEventPriceTypeData>

// ソート関連
export const SORT_TYPE = {
  NEWER: 'newer',
  OLDER: 'older',
  LIKED: 'liked',
  POPULAR: 'popular',
  RECENTLY: 'recently',
  FINISHED: 'finished',
} as const

export const sortType = z.enum([
  SORT_TYPE.NEWER,
  SORT_TYPE.OLDER,
  SORT_TYPE.LIKED,
  SORT_TYPE.POPULAR,
  SORT_TYPE.RECENTLY,
  SORT_TYPE.FINISHED,
])

export type SortType = z.infer<typeof sortType>

export const myVketEventSortData = z.object({
  value: sortType,
  text: z.string(),
})

export type MyVketEventSortData = z.infer<typeof myVketEventSortData>

export const myVketEventUser = z.object({
  id: z.number(),
  userId: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string(),
  platformCode: z.string(),
  icon: iconResponse,
  topPageAvatarId: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
})
export type MyVketEventUser = z.infer<typeof myVketEventUser>

export const participationUser = myVketEventUser.extend({
  blockStatus: z.boolean(),
  followStatus: z.boolean(),
})
export type ParticipationUser = z.infer<typeof participationUser>

export const achievement = z.object({
  title: z.string(),
  description: z.string(),
  titleEn: z.string(),
  descriptionEn: z.string(),
  requirements: z.string(),
  thumbnail: z.object({ url: z.string() }),
  ingameThumbnail: z.object({ url: z.string().nullable() }),
})

export type Achievement = z.infer<typeof achievement>

export const user = z.object({
  id: z.number(),
  userId: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string().nullable(),
  platformCode: z.string(),
  icon: iconResponse,
  topPageAvatarId: z.number().nullable(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
})
export type User = z.infer<typeof user>

export const WORLD_TYPE = {
  /** ルーム */
  HOUSING_WORLD: 'HousingWorld',
  /** ワールド */
  WORLD_PORTAL: 'WorldPortal',
} as const
export const worldType = z.enum([
  WORLD_TYPE.HOUSING_WORLD,
  WORLD_TYPE.WORLD_PORTAL,
])
export type WorldType = z.infer<typeof worldType>

export const AVAILABLE_PARTICIPANT = {
  /** 誰でも */
  ALL: 'all',
  /** 相互フォローのみ */
  MUTUAL_FOLLOW_ONLY: 'mutual_follow_only',
  /** 一覧に非表示（URLから直接のみ） */
  UNLISTED: 'unlisted',
} as const
export const availableParticipant = z.enum([
  AVAILABLE_PARTICIPANT.ALL,
  AVAILABLE_PARTICIPANT.MUTUAL_FOLLOW_ONLY,
  AVAILABLE_PARTICIPANT.UNLISTED,
])
export type AvailableParticipant = z.infer<typeof availableParticipant>

export const eventCollaborator = z.object({
  id: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string().nullable(),
  icon: iconResponse,
  topPageAvatarId: z.number().nullable(),
  followStatus: z.boolean(),
})
export type EventCollaborator = z.infer<typeof eventCollaborator>

export const world = z.object({
  worldId: z.string(),
  worldSetId: z.number(),
  name: z.string(),
  description: z.string().nullable(),
  thumbnail: z.object({
    url: z.string().nullable(),
  }),
  platformCode: z.string(),
})
export type World = z.infer<typeof world>

export const myVketEvent = z.object({
  id: z.number(),
  userId: z.number(),
  user,
  name: z.string(),
  description: z.string(),
  thumbnail: z.string(),
  startAt: z.union([z.instanceof(Date), z.string()]),
  endAt: z.union([z.instanceof(Date), z.string()]),
  numberOfLikes: z.number(),
  numberOfParticipants: z.number(),
  worldSetId: z.number().nullable(),
  worldName: z.string().nullable(),
  worldUrl: z.string(),
  worldType,
  availableParticipant,
  display: z.boolean(),
  isPaid: z.boolean(),
  entryFee: z.number(),
  entryCount: z.number(),
  ticketItemId: z.number().nullable(),
  isOfficial: z.boolean(),
  isCanceled: z.boolean(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
  liked: z.boolean().optional(),
  participated: z.boolean(),
  achievements: z.array(
    achievement.pick({
      title: true,
      description: true,
      titleEn: true,
      descriptionEn: true,
      thumbnail: true,
      ingameThumbnail: true,
    })
  ),
  eventCollaborators: z.array(eventCollaborator),
  world: world.nullable(),
})
export type MyVketEvent = z.infer<typeof myVketEvent>

/**
 * MyVketEventとMyVketEventList[0]が異なる運用をされているため、定義しました。
 */
export const myVketEventListElement = myVketEvent.pick({
  id: true,
  userId: true,
  user: true,
  name: true,
  description: true,
  thumbnail: true,
  startAt: true,
  endAt: true,
  numberOfLikes: true,
  numberOfParticipants: true,
  worldSetId: true,
  worldName: true,
  worldUrl: true,
  worldType: true,
  availableParticipant: true,
  display: true,
  isPaid: true,
  entryFee: true,
  entryCount: true,
  isOfficial: true,
  isCanceled: true,
  ticketItemId: true,
  createdAt: true,
  updatedAt: true,
  achievements: true,
  liked: true,
})
export type MyVketEventListElement = z.infer<typeof myVketEventListElement>

export const myVketEventList = z.array(myVketEventListElement)
export type MyVketEventList = z.infer<typeof myVketEventList>

export const successMessageResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})

export type SuccessMessageResponse = z.infer<typeof successMessageResponse>

export const eventParticipants = z.object({
  id: z.number(),
  vketId: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  name: z.string(),
  introduction: z.string(),
  icon: z.object({
    url: z.string(),
    circle: z
      .object({
        url: z.string(),
      })
      .optional(),
  }),
  topPageAvatarId: z.number(),
  blockStatus: z.boolean().optional(),
  followStatus: z.boolean().optional(),
  sub: z.string().optional(),
})

export type EventParticipants = z.infer<typeof eventParticipants>

export const EVENT_MANAGEMENT_OPTION = {
  plannedParticipationEvents: 'plannedParticipationEvents',
  noLongerBeingHeldEvents: 'noLongerBeingHeldEvents',
} as const

export const eventManagementOption = z.enum([
  EVENT_MANAGEMENT_OPTION.plannedParticipationEvents,
  EVENT_MANAGEMENT_OPTION.noLongerBeingHeldEvents,
])

export type EventManagementOption = z.infer<typeof eventManagementOption>
