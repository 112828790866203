import { z } from 'zod'
import { useApi } from './useApi'
import { createUrlSearchParams } from '@/utils/url'
import { SuccessMessage } from '@/models/successMessage'
import { present } from '@/models/present'

// types
export const getPresentsRequest = z.object({
  isOpened: z.boolean().optional(),
  limit: z.number(),
  offset: z.number(),
})

export type GetPresentsRequest = z.infer<typeof getPresentsRequest>

export const getPresentsResponse = z.object({
  presentCount: z.number(),
  presents: z.array(present),
})

export type GetPresentsResponse = z.infer<typeof getPresentsResponse>

export const openPresentRequest = z.object({
  platformCode: z.string(),
})

export type OpenPresentRequest = z.infer<typeof openPresentRequest>

const prefix = '/presents'

export const usePresentRepository = () => {
  const api = useApi()

  return {
    get: {
      /**
       * プレゼントの一覧を取得する
       */
      getPresents(params: GetPresentsRequest): Promise<GetPresentsResponse> {
        const queryContent = createUrlSearchParams(params)
        return api.get(`${prefix}?${queryContent.toString()}`)
      },
    },
    post: {},
    put: {},
    patch: {
      /**
       * プレゼントを受け取る
       */
      openPresent(id: string | number): Promise<SuccessMessage> {
        return api.patch(`${prefix}/${id}`)
      },
    },
    delete: {},
  }
}
