import { z } from 'zod'
import { presetHousingScene } from './presetHousingScene'

export const presetHousingHome = z.object({
  id: z.number(),
  platformCode: z.string(),
  name: z.string(),
  path: z.string(),
  filename: z.string(),
  order: z.number(),
  tutorialUse: z.boolean(),
  homeJson: z.string(),
  detailThumbnailUrl: z.string(),
  listThumbnailUrl: z.string(),
  tutorialThumbnailUrl: z.string(),
  tutorialDetailImageUrl: z.string(),
  tutorialBgImageUrl: z.string(),
  presetHousingScenes: z.array(presetHousingScene).optional(),
  createdAt: z.string(),
  updatedAt: z.string(),
})

export type PresetHousingHome = z.infer<typeof presetHousingHome>
