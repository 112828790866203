import { z } from 'zod'

export const contactDataSchema = z.object({
  name: z.string(),
  email: z.string().email().optional(),
  contactEmail: z.string().email().optional(),
  vketId: z.string().nullable().optional(),
  category: z.string(),
  body: z.string(),
  image1: z.instanceof(File).nullable().optional(),
  image2: z.instanceof(File).nullable().optional(),
  image3: z.instanceof(File).nullable().optional(),
  occurredAt: z.string().nullable().optional(),
  forcedPrivateItem: z.string().nullable().optional(),
  device: z.string().nullable().optional(),
  os: z.string().nullable().optional(),
  browser: z.string().nullable().optional(),
  occurredWorld: z.string().nullable().optional(),
  occurredFrequency: z.string().nullable().optional(),
  violatingUser: z.string().nullable().optional(),
  victimizedUser: z.string().nullable().optional(),
})
export type ContactData = z.infer<typeof contactDataSchema>

export const contactFormData = contactDataSchema.extend({
  agree: z.boolean(),
  osOther: z.string().optional(),
  browserOther: z.string().optional(),
})
export type ContactFormData = z.infer<typeof contactFormData>
