import { z } from 'zod'

import { itemPurchased } from './cloudstore'

// models
import { iconResponse } from '@/models/file'

export const achievement = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  titleEn: z.string(),
  descriptionEn: z.string(),
  thumbnail: z.object({ url: z.string() }),
  ingameThumbnail: z.object({ url: z.string() }),
  accomplishedAt: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
})

export type Achievement = z.infer<typeof achievement>

export const userAchievement = z.object({
  label: z.string(),
  title: z.string(),
  description: z.string(),
  titleEn: z.string(),
  descriptionEn: z.string(),
  thumbnail: z.object({ url: z.string() }),
  ingameThumbnail: z.object({ url: z.string().nullable() }),
  accomplishedAt: z.string(),
  isAccomplished: z.boolean(),
  accomplishedUsersCount: z.number(),
  showProfile: z.boolean(),
  createdAt: z.string(),
  updatedAt: z.string(),
})

export type UserAchievement = z.infer<typeof userAchievement>

export const accomplishedUser = z.object({
  id: z.number(),
  userId: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string().nullable(),
  platformCode: z.string(),
  icon: iconResponse,
  createdAt: z.union([z.instanceof(Date), z.string()]), // date
  updatedAt: z.union([z.instanceof(Date), z.string()]), // date
})

export type AccomplishedUser = z.infer<typeof accomplishedUser>

export const achievementReward = z.object({
  id: z.number(),
  achievementId: z.number(),
  cloudStoreItemId: z.number(),
  presentId: z.number().nullable(),
  openedAt: z.string().nullable(),
})

export const achievementDetail = z.object({
  id: z.number(),
  title: z.string(),
  description: z.string(),
  titleEn: z.string(),
  descriptionEn: z.string(),
  thumbnail: z.object({ url: z.string() }),
  ingameThumbnail: z.object({ url: z.string().nullable() }),
  accomplishedAt: z.string().optional().nullable(), // 獲得日時。ログイン時 + 獲得済みの時返ってくる
  showProfile: z.boolean().optional().nullable(), // プロフィールに表示するかどうか。ログイン時のみ返ってくる
  isAccomplished: z.boolean(),
  accomplishedUsersCount: z.number(),
  accomplishedUsers: z.array(accomplishedUser),
  achievementRewards: z.array(achievementReward),
})

export type AchievementDetail = z.infer<typeof achievementDetail>

export const achievementItem = achievementDetail.extend({
  achievementRewards: z.array(
    achievementReward.extend({
      cloudStoreItem: itemPurchased.optional(),
    })
  ),
})

export type AchievementItem = z.infer<typeof achievementItem>

export const getAchievementsDetailRequest = z.object({
  label: z.string(),
})

export type GetAchievementsDetailRequest = z.infer<
  typeof getAchievementsDetailRequest
>

export const getOtherAchievementsDetailResponse = z.object({
  achievementsDetail: z.array(achievementDetail),
})

export type GetAchievementsDetailResponse = z.infer<
  typeof getOtherAchievementsDetailResponse
>

// ソート関連
export const SORT_TYPE = {
  NEW: 'new',
  OLD: 'old',
  // 取得ユーザーが多い
  MANY: 'many',
  // 取得ユーザーが少ない
  FEW: 'few',
} as const

export const sortType = z.enum([
  SORT_TYPE.NEW,
  SORT_TYPE.OLD,
  SORT_TYPE.MANY,
  SORT_TYPE.FEW,
])

export type SortType = z.infer<typeof sortType>

export const sortData = z.object({
  value: sortType,
  text: z.string(),
})

export type SortData = z.infer<typeof sortData>
