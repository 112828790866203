import { z } from 'zod'

export const DEVICE_TYPE = {
  PC: 'pc',
  IOS: 'ios',
  ANDROID: 'android',
} as const
export const deviceType = z.enum([
  DEVICE_TYPE.PC,
  DEVICE_TYPE.IOS,
  DEVICE_TYPE.ANDROID,
])
export type DeviceType = z.infer<typeof deviceType>

export const pushSetting = z.object({
  id: z.number(),
  userId: z.number(),
  notification: z.boolean(),
  news: z.boolean(),
  event: z.boolean(),
  like: z.boolean(),
  sns: z.boolean(),
  message: z.boolean(),
  room: z.boolean(),
  present: z.boolean(),
  store: z.boolean(),
  platformCode: z.string(),
  createdAt: z.union([z.instanceof(Date), z.string()]),
  updatedAt: z.union([z.instanceof(Date), z.string()]),
})
export type PushSetting = z.infer<typeof pushSetting>

export const sendBody = z.object({
  title: z.string(),
  body: z.string(),
  click_action: z.string(),
  image: z.string(),
})
export type SendBody = z.infer<typeof sendBody>
