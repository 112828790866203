// NOTE: housing_scene とは別のAPI、モデルだけど将来的に housing_worlds に統一する
//       housing_worlds = アウトゲームでのみ使う情報
//       housing_scene = インゲーム側でも使う情報

import { z } from 'zod'
import { useApi } from './useApi'
import {
  GetHousingWorldRequest,
  GetHousingWorldResponse,
  GetHousingWorldsResponse,
  GetHousingWorldsRequest,
  housingWorld,
} from '@/models/housingWorld'
import { createUrlSearchParams } from '@/utils/url'

// ▼▼▼▼ 各種APIリクエスト、レスポンス ▼▼▼▼
// NOTE: 対象のAPIに修正が入るときにmodelsにあるzod定義を移植する
export const postHousingWorldsListRequest = z.object({
  worldIds: z.array(z.string()),
})
export const postHousingWorldsListResponse = z.object({
  housingWorlds: z.array(housingWorld),
})
export type PostHousingWorldsListRequest = z.infer<
  typeof postHousingWorldsListRequest
>
export type PostHousingWorldsListResponse = z.infer<
  typeof postHousingWorldsListResponse
>
// ▲▲▲▲ 各種APIリクエスト、レスポンス ▲▲▲▲

const prefix: string = '/housing_worlds'

export const useHousingWorldsRepository = () => {
  const api = useApi()
  return {
    get: {
      getHousingWorld({
        worldId,
      }: GetHousingWorldRequest): Promise<GetHousingWorldResponse> {
        return api.get(`${prefix}/${worldId}`)
      },
      getHousingWorlds(
        params: GetHousingWorldsRequest
      ): Promise<GetHousingWorldsResponse> {
        const queryContent = {
          limit: params.limit,
          offset: params.offset,
          sort: params.sort,
          search: params.search,
          filter: params.filter,
        }
        const query = createUrlSearchParams(queryContent)
        return api.get(`${prefix}?${query.toString()}`)
      },
      getRecommendedHousingWorlds(): Promise<GetHousingWorldsResponse> {
        return api.get(`/recommended_housing_worlds`)
      },
    },
    post: {
      /**
       * 指定のworld_idのハウジングワールド情報を一括で取得する。
       * @param params
       * @returns
       */
      getHousingWorldsList(
        params: PostHousingWorldsListRequest
      ): Promise<PostHousingWorldsListResponse> {
        return api.post(`${prefix}/list`, {
          body: {
            world_ids: params.worldIds,
          },
        })
      },
    },
    put: {},
    patch: {},
    delete: {},
  }
}
