import { z } from 'zod'

export const presetHousingScene = z.object({
  id: z.number(),
  presetHousingHomeId: z.number(),
  platformCode: z.string(),
  name: z.string(),
  sceneJson: z.string(),
  order: z.number(),
  thumbnail: z.object({
    url: z.string().nullable(),
  }),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
})

export type PresetHousingScene = z.infer<typeof presetHousingScene>
