import type { InjectionKey } from 'vue'

import { useApi } from './useApi'

import {
  GetBlocksRequest,
  GetBlocksResponse,
  PostBlocksRequest,
  PostBlocksResponse,
  DeleteBlocksRequest,
  DeleteBlocksResponse,
} from '@/models/blocks'

const prefix = '/user/blocks'

export const useBlocksRepository = () => {
  const api = useApi()

  return {
    get: {
      async getBlocks(params: GetBlocksRequest): Promise<GetBlocksResponse> {
        const query =
          params.limit !== undefined && params.offset !== undefined
            ? `?limit=${params.limit}&offset=${params.offset}`
            : ''
        return await api.get(`${prefix}${query}`)
      },
    },
    post: {
      async postBlocks(
        postData: PostBlocksRequest
      ): Promise<PostBlocksResponse> {
        return await api.post(`${prefix}`, { body: postData })
      },
    },
    put: {},
    patch: {},
    delete: {
      async deleteBlocks(
        deleteData: DeleteBlocksRequest
      ): Promise<DeleteBlocksResponse> {
        return await api.delete(`${prefix}/${deleteData.vketId}`)
      },
    },
  }
}

export default useBlocksRepository

export type BlocksRepositoryComposable = ReturnType<typeof useBlocksRepository>
export const BlocksRepositoryInjectionKey: InjectionKey<BlocksRepositoryComposable> =
  Symbol('blocksRepositoryComposable')
