import { z } from 'zod'
import {
  userAchievement,
  achievement,
  achievementDetail,
  sortType,
} from '@/models/achievement'
import { useApi } from '@/composables/repository/useApi'
import { createUrlSearchParams } from '@/utils/url'

const prefixPath: string = '/user/achievements'

export const getAchievementsRequest = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
  sort: sortType,
})

export type GetAchievementsRequest = z.infer<typeof getAchievementsRequest>

export const getAchievementsResponse = z.object({
  achievements: z.array(userAchievement),
  achievementCount: z.number(),
  totalAchievementCount: z.number(),
})

export type GetAchievementsResponse = z.infer<typeof getAchievementsResponse>

export const getAchievementDetailRequest = z.object({
  label: z.string(),
  accomplishedUsersLimit: z.number().optional(),
  accomplishedUsersOffset: z.number().optional(),
})

export type GetAchievementDetailRequest = z.infer<
  typeof getAchievementDetailRequest
>

export const getAchievementDetailResponse = z.object({
  achievement: achievementDetail,
})

export type GetAchievementDetailResponse = z.infer<
  typeof getAchievementDetailResponse
>

export const getOtherAchievementsRequest = z.object({
  vketId: z.string(),
  limit: z.number(),
  offset: z.number(),
})

export type GetOtherAchievementsRequest = z.infer<
  typeof getOtherAchievementsRequest
>

export const getOtherAchievementsDetailRequest = z.object({
  vketId: z.string(),
  label: z.string(),
})

export type GetOtherAchievementsDetailRequest = z.infer<
  typeof getOtherAchievementsDetailRequest
>

export const postGrantAchievementRequest = z.object({
  label: z.string(),
})

export type PostGrantAchievementRequest = z.infer<
  typeof postGrantAchievementRequest
>

export const postGrantAchievementResponse = z.object({
  achievement,
})

export type PostGrantAchievementResponse = z.infer<
  typeof postGrantAchievementResponse
>

export const patchAchievementDetailRequest = z.object({
  label: z.string(),
  showProfile: z.boolean(),
})

export type PatchAchievementDetailRequest = z.infer<
  typeof patchAchievementDetailRequest
>

export const patchAchievementDetailResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})

export type PatchOtherAchievementsDetailResponse = z.infer<
  typeof patchAchievementDetailResponse
>

export const useAchievementRepository = () => {
  const api = useApi()

  return {
    get: {
      async getAchievements(
        params: GetAchievementsRequest
      ): Promise<GetAchievementsResponse> {
        const queryContent = {
          limit: params.limit,
          offset: params.offset,
          sort: params.sort,
        }

        const query = createUrlSearchParams(queryContent)
        return await api.get(`${prefixPath}${query ? '?' + query : ''}`)
      },
      async getAchievement(
        getData: GetAchievementDetailRequest
      ): Promise<GetAchievementDetailResponse> {
        const { label, accomplishedUsersLimit, accomplishedUsersOffset } =
          getData
        return await api.get(`${prefixPath}/${label}`, {
          params: {
            accomplished_users_limit: accomplishedUsersLimit,
            accomplished_users_offset: accomplishedUsersOffset,
          },
        })
      },
      // 他人のアチーブメント一覧取得
      async getOhterAchievements(
        getData: GetOtherAchievementsRequest
      ): Promise<GetAchievementsResponse> {
        const { vketId, limit, offset } = getData
        return await api.get(`${prefixPath}/${vketId}/list`, {
          params: { limit, offset },
        })
      },
      // 他人のアチーブメント詳細取得
      async getOhterAchievementDetail(
        getData: GetOtherAchievementsDetailRequest
      ): Promise<GetAchievementsResponse> {
        const { vketId, label } = getData
        return await api.get(`${prefixPath}/${vketId}/detail/${label}`)
      },
    },
    post: {
      async postGrantAchievement({
        label,
      }: PostGrantAchievementRequest): Promise<PostGrantAchievementResponse> {
        // platform_code は固定
        return await api.post(`/achievements/grant/${label}`)
      },
    },
    put: {},
    patch: {
      async patchAchievementDetail({
        label,
        showProfile,
      }: PatchAchievementDetailRequest): Promise<PatchOtherAchievementsDetailResponse> {
        return await api.patch(`${prefixPath}/${label}`, {
          params: { show_profile: showProfile },
        })
      },
    },
    delete: {},
  }
}
