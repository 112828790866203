import { z } from 'zod'
import { useApi } from './useApi'

// models
import {
  myVketEventUserType,
  myVketEventsFilter,
  myVketEvent,
  eventParticipants,
  sortType,
  participatingMyVketEventFilter,
  worldType,
  availableParticipant,
  myVketEventList,
} from '@/models/myVketEvent'

// modules
import { createUrlSearchParams } from '@/utils/url'
import { objectToFormData } from '@/utils/form-data'

const prefix = '/events'

export const myVketEventParameters = z.object({
  name: z.string(),
  description: z.string().optional(),
  thumbnail: z.string().optional(),
  startAt: z.date().optional(),
  worldUrl: z.string().optional(),
  category: z.string().optional(),
  availableParticipant: myVketEventUserType.optional(),
  display: z.string().optional(),
})

export const getMyVketEventsRequest = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
  targetMonth: z
    .string()
    .regex(/[0-9]{4}[0-9]{2}[0-9]{2}/)
    .optional(),
  filter: myVketEventsFilter.optional(),
  startFrom: z.union([z.instanceof(Date), z.string()]).optional(),
  startTo: z.union([z.instanceof(Date), z.string()]).optional(),
  vketId: z.string().optional(),
  liked: z.string().optional(),
  keyword: z.string().optional(),
  sort: sortType.optional(),
  isPaid: z.boolean().optional(),
  finished: z.boolean().optional(), // 終了/中止を含む
  onlyFinished: z.boolean().optional(), // 終了/中止のみ
  endFrom: z.union([z.instanceof(Date), z.string()]).optional(),
  endTo: z.union([z.instanceof(Date), z.string()]).optional(),
})

export const getMyVketEventsRecentlyRequest = getMyVketEventsRequest
  .pick({
    endFrom: true,
    endTo: true,
    limit: true,
    offset: true,
    startFrom: true,
    startTo: true,
  })
  .extend({
    isOfficial: z.boolean().optional(),
  })

export const getParticipatingMyVketEventParameter = z.object({
  endFrom: z.string().optional(),
  endTo: z.string().optional(),
  filter: participatingMyVketEventFilter.optional(),
  finished: z.boolean().optional(), // 終了/中止を含む
  onlyFinished: z.boolean().optional(), // 終了/中止のみ
  isPaid: z.boolean().optional(),
  keyword: z.string().optional(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  sort: sortType.optional(),
  startFrom: z.union([z.instanceof(Date), z.string()]).optional(),
  startTo: z.union([z.instanceof(Date), z.string()]).optional(),
  targetMonth: z
    .string()
    .regex(/[0-9]{4}[0-9]{2}[0-9]{2}/)
    .optional(),
  vketId: z.string().optional(),
})

export type GetMyVketEventsRequest = z.infer<typeof getMyVketEventsRequest>

export type MyVketEventParameters = z.infer<typeof myVketEventParameters>

export type GetMyVketEventsRecentlyRequest = z.infer<
  typeof getMyVketEventsRecentlyRequest
>

export type GetParticipatingMyVketEventParameter = z.infer<
  typeof getParticipatingMyVketEventParameter
>

export const myVketEventResponse = z.object({
  event: myVketEvent,
})

export type MyVketEventResponse = z.infer<typeof myVketEventResponse>

export const getMyVketEventsResponse = z.object({
  eventCount: z.number(),
  events: myVketEventList,
})

export type GetMyVketEventsResponse = z.infer<typeof getMyVketEventsResponse>

export const getEventParticipantsResponse = z.object({
  userCount: z.number(),
  users: z.array(eventParticipants),
})

export type GetEventParticipantsResponse = z.infer<
  typeof getEventParticipantsResponse
>

export const successMessageResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})

export type SuccessMessageResponse = z.infer<typeof successMessageResponse>

export const postEventRequest = z.object({
  name: z.string(),
  description: z.string().optional(),
  thumbnail: z.union([z.instanceof(File), z.string()]).optional(),
  startAt: z.string().optional(),
  endAt: z.string().optional(),
  worldSetId: z.number().optional(),
  worldUrl: z.string().optional(),
  worldType: worldType.optional(),
  availableParticipant: availableParticipant.optional(),
  display: z.boolean().optional(),
  isPaid: z.boolean().optional(),
  entryFee: z.number().optional(),
  entryCount: z.number().optional(),
  eventCollaborators: z.array(z.string()).optional(),
})
export const postEventResponse = z.object({
  event: myVketEvent,
})
export type PostEventRequest = z.infer<typeof postEventRequest>
export type PostEventResponse = z.infer<typeof postEventResponse>

export const putEventRequest = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  thumbnail: z.union([z.instanceof(File), z.string()]).optional(),
  startAt: z.string().optional(),
  endAt: z.string().optional(),
  worldSetId: z.number().optional(),
  worldUrl: z.string().optional(),
  worldType: worldType.optional(),
  availableParticipant: availableParticipant.optional(),
  display: z.boolean().optional(),
  isCanceled: z.boolean().optional(),
  isPaid: z.boolean().optional(),
  entryFee: z.number().optional(),
  entryCount: z.number().optional(),
  eventCollaborators: z.array(z.string()).optional(),
})
export const putEventResponse = z.object({
  event: myVketEvent,
})
export type PutEventRequest = z.infer<typeof putEventRequest>
export type PutEventResponse = z.infer<typeof putEventResponse>

export const patchEventRequest = z.object({
  name: z.string(),
  description: z.string().optional(),
  thumbnail: z.union([z.instanceof(File), z.string()]).optional(),
  startAt: z.string().optional(),
  endAt: z.string().optional(),
  worldSetId: z.number().optional(),
  worldUrl: z.string().optional(),
  worldType: worldType.optional(),
  availableParticipant: availableParticipant.optional(),
  display: z.boolean().optional(),
  isCanceled: z.boolean().optional(),
  isPaid: z.boolean().optional(),
  entryFee: z.number().optional(),
  entryCount: z.number().optional(),
  eventCollaborators: z.array(z.string()).optional(),
})
export const patchEventResponse = z.object({
  event: myVketEvent,
})
export type PatchEventRequest = z.infer<typeof patchEventRequest>
export type PatchEventResponse = z.infer<typeof patchEventRequest>

export const postLikeEventResponse = z.object({
  event: myVketEvent,
})
export type PostLikeEventResponse = z.infer<typeof postLikeEventResponse>

export const deleteLikeEventResponse = z.object({ event: myVketEvent })
export type DeleteLikeEventResponse = z.infer<typeof deleteLikeEventResponse>

export const useMyVketEventRepository = () => {
  const api = useApi()

  return {
    get: {
      getMyVketEvent: (eventId: number): Promise<MyVketEventResponse> => {
        return api.get(`${prefix}/${eventId}`)
      },
      getMyVketEvents: async (
        param: GetMyVketEventsRequest
      ): Promise<GetMyVketEventsResponse> => {
        const queryContent = {
          limit: param.limit,
          offset: param.offset,
          target_month: param.targetMonth,
          filter: param.filter,
          start_from: param.startFrom,
          start_to: param.startTo,
          vket_id: param.vketId,
          keyword: param.keyword,
          sort: param.sort,
          is_paid: param.isPaid,
          liked: param.liked,
          finished: param.finished,
          only_finished: param.onlyFinished,
          end_from: param.endFrom,
          end_to: param.endTo,
        }

        const query = createUrlSearchParams(queryContent)

        return await api.get(`${prefix}/${query ? '?' + query : ''}`)
      },
      getMyVketEventParticipation: (
        eventId: number,
        limit?: number,
        offset?: number
      ): Promise<GetEventParticipantsResponse> => {
        const query =
          limit !== undefined && offset !== undefined
            ? `?limit=${limit}&offset=${offset}`
            : ''
        return api.get(`${prefix}/${eventId}/participants${query}`)
      },
      getMyVketEventsRecently: (
        param: GetMyVketEventsRecentlyRequest
      ): Promise<GetMyVketEventsResponse> => {
        const queryContent = {
          end_from: param.endFrom,
          end_to: param.endTo,
          is_official: param.isOfficial,
          limit: param.limit,
          offset: param.offset,
          start_from: param.startFrom,
          start_to: param.startTo,
        }

        const query = createUrlSearchParams(queryContent)
        return api.get(`/events/recently${query ? '?' + query : ''}`)
      },
      getParticipatingMyVketEvent: (
        param: GetParticipatingMyVketEventParameter
      ): Promise<GetMyVketEventsResponse> => {
        const queryContent = {
          endFrom: param.endFrom,
          endTo: param.endTo,
          filter: param.filter,
          finished: param.finished,
          only_finished: param.onlyFinished,
          isPaid: param.isPaid,
          keyword: param.keyword,
          limit: param.limit,
          offset: param.offset,
          sort: param.sort,
          startFrom: param.startFrom,
          startTo: param.startTo,
          target_month: param.targetMonth,
          vket_id: param.vketId,
        }

        const query = createUrlSearchParams(queryContent)
        return api.get(`/user/event_participants/${query ? '?' + query : ''}`)
      },
    },
    post: {
      createEvent: (
        eventData: PostEventRequest
      ): Promise<PostEventResponse> => {
        // NOTE: 画像を送信するために、FormDataを使用する
        const formData = objectToFormData(eventData)
        return api.post(`${prefix}`, {
          body: formData,
        })
      },
      resistorMyVketEvent: (eventId: number): Promise<SuccessMessageResponse> =>
        api.post(`${prefix}/${eventId}/participants`),
      likeMyVketEvent: (eventId: number): Promise<PostLikeEventResponse> => {
        return api.post('/event_likes', {
          body: {
            event_id: eventId,
          },
        })
      },
    },
    put: {
      putEvent: (eventId: number, eventData: PutEventRequest) => {
        // NOTE: 画像を送信するために、FormDataを使用する
        const formData = objectToFormData(eventData)
        return api.put(`${prefix}/${eventId}`, {
          body: formData,
        })
      },
    },
    patch: {
      updateMyVketEvent: (
        eventId: number,
        eventData: PatchEventRequest
      ): Promise<PatchEventResponse> =>
        api.patch(`/${eventId}`, {
          body: eventData,
        }),
    },
    delete: {
      deleteMyVketEvent: (eventId: number): Promise<SuccessMessageResponse> => {
        return api.delete(`${prefix}/${eventId}`)
      },
      unlikeMyVketEvent: (
        eventId: number
      ): Promise<DeleteLikeEventResponse> => {
        const stringEventId = eventId.toString()
        return api.delete(`/event_likes/${stringEventId}`)
      },
      unregisterMyVketEvent: (
        eventId: number
      ): Promise<SuccessMessageResponse> =>
        api.delete(`${prefix}/${eventId}/participants`),
    },
  }
}
