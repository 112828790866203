import { z } from 'zod'
import { useApi } from './useApi'
import { contactDataSchema } from '@/models/contact'
import { objectToFormData } from '@/utils/form-data'

export const submitContactRequestSchema = contactDataSchema
export type SubmitContactRequest = z.infer<typeof submitContactRequestSchema>

export const submitContactResponseSchema = z.object({
  success: z.object({
    message: z.string(),
  }),
})

export type SubmitContactResponse = z.infer<typeof submitContactResponseSchema>

export const useContactRepository = () => {
  const api = useApi()

  return {
    get: {},
    post: {
      async submitContact(
        contactData: SubmitContactRequest
      ): Promise<SubmitContactResponse> {
        const formData = objectToFormData(contactData)

        return await api.post('/contact/send', {
          body: formData,
        })
      },
    },
    put: {},
    patch: {},
    delete: {},
  }
}
