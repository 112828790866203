import { z } from 'zod'
import { useApi } from './useApi'
import {
  message,
  messageRoom,
  messageRoomSearch,
  messageTargetUser,
  messageSetting,
  messageCheckRoom,
  targetType,
  messageNoRoom,
} from '@/models/messageRoom'

const prefixRoom = '/user/rooms'
const prefixMessage = '/user/direct_messages'
const prefixSetting = '/user/message_settings'

// ルーム取得
export const getMessageRoomResponse = z.object({
  room: messageCheckRoom.or(messageNoRoom),
})
export type GetMessageRoomResponse = z.infer<typeof getMessageRoomResponse>

export const getMessageRoomResponseByRoomId = z.object({
  room: messageCheckRoom,
})
export type GetMessageRoomResponseByRoomId = z.infer<
  typeof getMessageRoomResponseByRoomId
>

// ルーム一覧取得
export const getMessageRoomsRequest = z.object({
  limit: z.number().optional(),
  offset: z.number().optional(),
})
export type GetMessageRoomsRequest = z.infer<typeof getMessageRoomsRequest>
export const getMessageRoomsResponse = z.object({
  rooms: z.array(messageRoom),
  roomCount: z.number(),
})
export type GetMessageRoomsResponse = z.infer<typeof getMessageRoomsResponse>

// ルーム内メッセージ取得
export const getRoomMessagesRequest = z.object({
  roomId: z.number(),
  limit: z.number().optional(),
  offset: z.number().optional(),
  lastMessageLimit: z.number().optional(),
  limitId: z.number().optional(),
  offsetId: z.number().optional(),
})
export type GetRoomMessagesRequest = z.infer<typeof getRoomMessagesRequest>
export const getRoomMessagesResponse = z.object({
  directMessages: z.array(message.omit({ vketId: true })),
  blockStatus: z.boolean(),
  blockedStatus: z.boolean(),
  mutualFollowStatus: z.boolean(),
})
export type GetRoomMessagesResponse = z.infer<typeof getRoomMessagesResponse>

// ルーム既読
export const getRoomMessageReadResponse = z.object({
  messages: z.array(message.omit({ vketId: true, user: true })),
})
export type GetRoomMessageReadResponse = z.infer<
  typeof getRoomMessageReadResponse
>

// ルーム検索
export const getRoomSearchRequest = z.object({
  word: z.string(),
})
export type GetRoomSearchRequest = z.infer<typeof getRoomSearchRequest>
export const getRoomSearchResponse = z.object({
  rooms: z.array(messageRoomSearch),
})
export type GetRoomSearchResponse = z.infer<typeof getRoomSearchResponse>

// (ユーザ用)メッセージ未読件数取得
export const getRoomMessagesUnreadCountResponse = z.object({
  unreadCount: z.number(),
})
export type GetRoomMessagesUnreadCountResponse = z.infer<
  typeof getRoomMessagesUnreadCountResponse
>

// メッセージ作成
export const postDirectMessageRequest = z.object({
  roomId: z.number().optional(),
  vketId: z.string().optional(),
  message: z.string(),
})
export type PostDirectMessageRequest = z.infer<typeof postDirectMessageRequest>

export const postDirectMessageResponse = z.object({
  directMessage: z.object({
    id: z.number(),
    userId: z.number(),
    vketId: z.string(),
    roomId: z.number(),
    message: z.string(),
    isRead: z.boolean(),
    isHidden: z.boolean(),
    createdAt: z.string(),
    updatedAt: z.string(),
  }),
})
export type PostDirectMessageResponse = z.infer<
  typeof postDirectMessageResponse
>

// ルーム削除
export const deleteRoomResponse = z.object({
  success: z.object({
    message: z.string(),
  }),
})
export type DeleteRoomResponse = z.infer<typeof deleteRoomResponse>

// DM可能ユーザー一覧
export const getMessageUserRequest = z.object({
  followed: z.boolean().optional(),
  following: z.boolean().optional(),
  limit: z.number().optional(),
  mutualFollowing: z.string().optional(),
  name: z.string().optional(),
  offset: z.number().optional(),
})
export type GetMessageUserRequest = z.infer<typeof getMessageUserRequest>
export const getMessageUserResponse = z.object({
  userCount: z.number(),
  users: z.array(messageTargetUser),
})
export type GetMessageUserResponse = z.infer<typeof getMessageUserResponse>
export const getMessageSettingsResponse = z.object({
  messageSetting,
})
export type GetMessageSettingsResponse = z.infer<
  typeof getMessageSettingsResponse
>

// メッセージ設定更新
export const postMessageSettingsRequest = z.object({
  // message: z.boolean(), // 2024/03/05現在使用しない。常にtrueが入る。
  target: targetType,
})
export const postMessageSettingsResponse = z.object({
  messageSetting,
})
export type PostMessageSettingsRequest = z.infer<
  typeof postMessageSettingsRequest
>
export type PostMessageSettingsResponse = z.infer<
  typeof postMessageSettingsResponse
>

export const useMessageRoomRepository = () => {
  const api = useApi()

  return {
    get: {
      /** (ユーザ用)ルームを取得する */
      async getRoom(vketId: string): Promise<GetMessageRoomResponse> {
        return await api.get(`${prefixRoom}/check/${vketId}`)
      },
      /** (ユーザ用)ルームを取得する(roomId) */
      async getRoomByRoomId(
        roomId: number
      ): Promise<GetMessageRoomResponseByRoomId> {
        return await api.get(`${prefixRoom}/check_room/${roomId}`)
      },
      /** (ユーザ用)ルーム一覧取得 */
      async getRooms(
        getData: GetMessageRoomsRequest
      ): Promise<GetMessageRoomsResponse> {
        const { limit, offset } = getData
        return await api.get(`${prefixRoom}`, {
          params: { limit, offset },
        })
      },
      /** (ユーザ用)ルーム内メッセージ取得 */
      async getRoomMessages(
        params: GetRoomMessagesRequest
      ): Promise<GetRoomMessagesResponse> {
        const queryContent = {
          limit: params.limit,
          offset: params.offset,
          limit_id: params.limitId,
          offset_id: params.offsetId,
          last_message_limit: params.lastMessageLimit,
        }

        const query = new URLSearchParams(
          Object.fromEntries(
            Object.entries(queryContent)
              .filter((q): q is [string, number] => q[1] !== undefined)
              .map((q) => [q[0], q[1].toString()])
          )
        )
        return await api.get(
          `${prefixRoom}/${params.roomId}?${query.toString()}`
        )
      },
      /** (ユーザ用)メッセージ既読 */
      async getRoomRead(roomId: number): Promise<GetRoomMessageReadResponse> {
        return await api.get(`${prefixMessage}/read?room_id=${roomId}`)
      },
      /** ルーム検索 */
      async getRoomsSearch(
        params: GetRoomSearchRequest
      ): Promise<GetRoomSearchResponse> {
        return await api.get(`${prefixRoom}/search?word=${params.word}`)
      },
      /** (ユーザ用)メッセージ未読件数取得 */
      async getRoomMessagesUnreadCount(): Promise<GetRoomMessagesUnreadCountResponse> {
        return await api.get(`${prefixMessage}/unread_count`)
      },
      /** (ユーザ用)DM可能ユーザー一覧 */
      getMessageUser(
        params: GetMessageUserRequest
      ): Promise<GetMessageUserResponse> {
        const query = createUrlSearchParams(params)
        return api.get(`/user/message_users?${query}`)
      },
      /** メッセージの設定取得 */
      async getMessageSettings(): Promise<GetMessageSettingsResponse> {
        return await api.get(prefixSetting)
      },
    },
    post: {
      /** メッセージ送信 */
      async postRoomMessage(
        postData: PostDirectMessageRequest
      ): Promise<PostDirectMessageResponse> {
        const formData = new FormData()
        if (postData.roomId) {
          formData.append('room_id', postData.roomId.toString())
        }
        if (postData.vketId) {
          formData.append('vket_id', postData.vketId)
        }
        formData.append('message', postData.message)

        return await api.post(`${prefixMessage}`, {
          body: formData,
        })
      },
      /** メッセージの設定更新 */
      async postMessageSettings(
        postData: PostMessageSettingsRequest
      ): Promise<PostMessageSettingsResponse> {
        const formData = new FormData()
        formData.append('target', postData.target)

        return await api.post(`${prefixSetting}`, {
          body: formData,
        })
      },
    },
    put: {},
    patch: {},
    delete: {
      /** ルーム削除 */
      async deleteRoom(id: string): Promise<DeleteRoomResponse> {
        return await api.delete(`${prefixRoom}/${id}`)
      },
    },
  }
}
