// NOTE: housing_scene とは別のAPI、モデルだけど将来的に housing_worlds に統一する
//       housing_worlds = アウトゲームでのみ使う情報
//       housing_scene = インゲーム側でも使う情報

import { z } from 'zod'
import { iconResponse } from './file'
import { profileData } from './profiles'

const userData = z.object({
  id: z.number(),
  vketId: z.string(),
  name: z.string(),
  introduction: z.string().nullable(),
  topPageAvatarId: z.number().nullable(),
  icon: iconResponse,
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
})

// コンテンツタイプ関連
export const CONTENT_TYPE = {
  USER: 'user',
  LIKES: 'likes',
} as const

export const contentType = z.enum([CONTENT_TYPE.USER, CONTENT_TYPE.LIKES])

export type ContentType = z.infer<typeof contentType>

export const housingWorldContentTypeData = z.object({
  value: contentType,
  label: z.string(),
  labelEn: z.string(),
})

export type HousingWorldContentTypeData = z.infer<
  typeof housingWorldContentTypeData
>

// ソート関連
export const SORT_TYPE = {
  NEWER: 'newer',
  OLDER: 'older',
  POPULAR: 'popular',
  VISITED: 'visited',
  UNVISITED: 'unvisited',
  FAVORITE: 'favorite',
  UNFAVORITE: 'unfavorite',
  OFFICIAL: 'official',
} as const

export const sortType = z.enum([
  SORT_TYPE.NEWER,
  SORT_TYPE.OLDER,
  SORT_TYPE.POPULAR,
  SORT_TYPE.VISITED,
  SORT_TYPE.UNVISITED,
  SORT_TYPE.FAVORITE,
  SORT_TYPE.UNFAVORITE,
  SORT_TYPE.OFFICIAL,
])

export type SortType = z.infer<typeof sortType>

export const housingWorldSortData = z.object({
  value: sortType,
  text: z.string(),
})

export type HousingWorldSortData = z.infer<typeof housingWorldSortData>

// scene = 各ルーム
export const housingWorld = z.object({
  id: z.number(),
  housingSceneId: z.number(),
  worldId: z.string().nullable(),
  worldSetId: z.number(),
  name: z.string().nullable(),
  description: z.string().nullable(),
  shortDescription: z.string().nullable(),
  isOfficial: z.boolean().nullable(),
  tags: z.array(z.string()),
  thumbnail: z.object({
    url: z.string().nullable(),
  }),
  // NOTE: マイグレーションが実行されたらnull入らなくなる
  maxNumberOfPeople: z.number().nullable(),
  numberOfLikes: z.number(),
  numberOfVisits: z.number(),
  platformCode: z.string(),
  creatorName: z.string().nullable(),
  createdAt: z.string().nullable(),
  updatedAt: z.string().nullable(),
  vketId: z.string(),
  liked: z.boolean().optional(),
  user: userData,
})

export type HousingWorld = z.infer<typeof housingWorld>

export const housingWorldWithProfile = housingWorld.extend({
  profile: profileData.nullable(),
})

export type HousingWorldWithProfile = z.infer<typeof housingWorldWithProfile>

export const FILTER_TYPE = {
  OFFICIAL: 'official',
  USER: 'user',
} as const

export const filterType = z.enum([FILTER_TYPE.OFFICIAL, FILTER_TYPE.USER])

// 検索クエリ
export const searchQueryOptions = z.object({
  search: z.string().optional(),
  sort: sortType.optional(),
  filter: filterType.optional(),
})

export type SearchQueryOptions = z.infer<typeof searchQueryOptions>

// get

export const getHousingWorldRequest = z.object({
  worldId: z.string(),
})

export type GetHousingWorldRequest = z.infer<typeof getHousingWorldRequest>

export const getHousingWorldResponse = z.object({
  housingWorld,
})

export type GetHousingWorldResponse = z.infer<typeof getHousingWorldResponse>

export const getHousingWorldsRequest = z.object({
  limit: z.number(),
  offset: z.number(),
  search: z.string().optional(),
  sort: sortType.optional(),
  filter: filterType.optional(),
})

export type GetHousingWorldsRequest = z.infer<typeof getHousingWorldsRequest>

export const getHousingWorldsResponse = z.object({
  housingWorlds: z.array(housingWorld),
  housingWorldCount: z.number().optional(),
})

export type GetHousingWorldsResponse = z.infer<typeof getHousingWorldsResponse>
