import { z } from 'zod'

export const iconResponse = z.object({
  circle: z
    .object({
      url: z.string().nullable(),
    })
    .optional(),
  url: z.string().nullable(),
})

export const thumbnail = z.object({
  url: z.string().nullable(),
})

export type IconResponse = z.infer<typeof iconResponse>
