import type { InjectionKey } from 'vue'

import { useApi } from './useApi'
import {
  GetProfileRequest,
  GetProfileResponse,
  GetProfileDetailsResponse,
  PostProfileRequest,
  PostProfileResponse,
  GetProfileListRequest,
  GetProfileListResponse,
  PatchProfileRequest,
  PatchProfileResponse,
} from '@/models/profiles'

export type PostTokenResponse = {
  access_token: string
  token_type: string
  expires_in: number
  refresh_token: string
  scope: string
  created_at: number
  id_token: string
}

const prefix = '/user/profiles'

export const useProfileRepository = () => {
  const api = useApi()

  return {
    get: {
      async getMyProfile(): Promise<GetProfileResponse> {
        return await api.get(`${prefix}`)
      },
      async getProfileById(
        getData: GetProfileRequest
      ): Promise<GetProfileDetailsResponse> {
        return await api.get(`${prefix}/${getData.vketId}`)
      },
    },
    post: {
      async postProfile(
        postData: PostProfileRequest
      ): Promise<PostProfileResponse | null> {
        const formData = new FormData()

        formData.append('name', postData.name)
        formData.append('introduction', postData.introduction)
        // topPageAvatarId があれば追加
        if (postData.topPageAvatarId) {
          formData.append(
            'top_page_avatar_id',
            String(postData.topPageAvatarId)
          )
        }
        // iconがあれば追加
        formData.append('icon', postData.icon || 'null')

        return await api.post(`${prefix}`, {
          body: formData,
        })
      },
      async getProfilesList(
        params: GetProfileListRequest
      ): Promise<GetProfileListResponse> {
        return await api.post(`${prefix}/list`, {
          body: params,
        })
      },
    },
    put: {},
    patch: {
      async patchProfile(
        patchData: PatchProfileRequest
      ): Promise<PatchProfileResponse | null> {
        const formData = new FormData()
        formData.append('name', patchData.name)
        if (patchData.introduction != null) {
          formData.append('introduction', patchData.introduction)
        }

        // null, undefined: 変更なし
        if (patchData.topPageAvatarId !== undefined) {
          formData.append(
            'top_page_avatar_id',
            String(patchData.topPageAvatarId)
          )
        }
        // true: 削除, false, undefined: 変更なし
        if (patchData.deleteTopPageAvatarId !== undefined) {
          formData.append(
            'delete_top_page_avatar_id',
            patchData.deleteTopPageAvatarId ? 'true' : 'false'
          )
        }
        // null: 削除, undefined: 変更なし
        if (patchData.icon !== undefined) {
          formData.append('icon', patchData.icon ? patchData.icon : 'null')
        }
        // null: 削除, undefined: 変更なし
        if (patchData.showAchievements !== undefined) {
          formData.append(
            'show_achievements',
            patchData.showAchievements ? 'true' : 'false'
          )
        }
        return await api.patch(`${prefix}`, {
          body: formData,
        })
      },
    },
    delete: {},
  }
}

export default useProfileRepository

export type PlofileRepositoryComposable = ReturnType<
  typeof useProfileRepository
>
export const apiInjectionKey: InjectionKey<PlofileRepositoryComposable> =
  Symbol('profileRepositoryComposable')
